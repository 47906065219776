import { Container } from "common/components";
import { IComponent } from "common/interface";
import React from "react";
import { useLocation } from 'react-router-dom';

/**
 * @description Body Component
 * @param {IComponent} props
 * @returns {React.ReactElement}
 */
const Body: React.FC<IComponent> = (
  props: IComponent
): React.ReactElement => {
  const { children } = props;
  const location = useLocation()
  const currentLocation = location.pathname
  return (
    <Container.BodyContainer style={currentLocation !== "/super-nft" ? { padding: 30 } : {}}>
      {children}
    </Container.BodyContainer>
  );
};

export default React.memo(Body);
