import "core/i18n";
import React from "react";
import { Outlet } from "react-router-dom";
import { Container } from "../components";
import SideMenu from "../components/SideMenu/index";
import ProfileSideBar from "../components/ProfileSideBar";
import AuthGuard from "../guards/AuthGuard";
import { ILayout } from "../interface";
import BodyLayout from "./Body";
import HeaderLayout from "./Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "common/hooks/redux";
import { RootState } from "store";
import { Spin } from "antd";
import { ROLES } from "common/constants/index";

const Layout: React.FC<ILayout> = (): React.ReactElement => {
	const location = useLocation();
	const navigate = useNavigate();
	const { user, authenticationLoading } = useAppSelector(
		(state: RootState) => state.auth
	);
	const isAdmin = user?.roles?.includes(ROLES.ADMIN);

	const currentLocation = location.pathname;

	if (
		currentLocation.includes("/admin") &&
		!isAdmin &&
		!authenticationLoading
	) {
		navigate("/");
	}
	return (
		<Container.LayoutContainer>
			{authenticationLoading ? (
				<Spin spinning={authenticationLoading} fullscreen />
			) : (
				<Container.RowContainer
					style={{
						alignItems: "",
						justifyContent: "",
						alignContent: "",
						padding: "32px",
					}}
				>
					{(() => {
						if (currentLocation.includes("/super-nft")) {
							return <div></div>;
						} else if (currentLocation.includes("profile")) {
							return <ProfileSideBar />;
						} else {
							return <SideMenu />;
						}
					})()}
					<Container.ColumnContainer style={{ width: "100%" }}>
						<HeaderLayout />

						<BodyLayout>
							<AuthGuard>
								<Outlet />
							</AuthGuard>
						</BodyLayout>
					</Container.ColumnContainer>
				</Container.RowContainer>
			)}
		</Container.LayoutContainer>
	);
};

export default Layout;
