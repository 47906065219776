import { AllUserStoreTypes } from "store/type";

export const initGlobalState: AllUserStoreTypes = {
  allUsers: [],
  usersRecentCount: 0,
  usersRecentList: [],
  getUsersLoading: false,
  updateUserLoading: false,
  usersRecentCountLoading: false,
  usersRecentListLoading: false,
};
