import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContType } from "common/type";
import axios from "./axios";

export const getMyFiles = createAsyncThunk("/asset", async () => {
	try {
		const response = await axios.get("/asset");
		return response.data;
	} catch (error: any) {
		console.log(error?.message, "error", error);
	}
});

export const saveMyFiles = createAsyncThunk(
	"upload/asset",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.post("/asset", data);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const getRecentFilesCount = createAsyncThunk(
	"get/asset/recent/{count}",
	async (count: any, thunkAPI) => {
		try {
			const response = await axios.get("asset/recent/" + count);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const deleteAsset = createAsyncThunk(
	"/asset/delete",
	async (id: number | null, thunkAPI) => {
		try {
			const response = await axios.delete("/asset/" + id);
			return id;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const getAssetDetail = createAsyncThunk('get/asset/{id}', async (AssetID: any, thunkAPI) => {
  try {
    const response = await axios.get('/asset/'+AssetID)
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});
