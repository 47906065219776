import React from "react";
import { Modal } from "antd";
import "./modals.scss";
import AssetPreview from "common/components/AssetPreview";
import { MyFilesType } from "common/type";

type propsType = {
  asset: MyFilesType | null;
  closePreview: () => void;
};

const AssetPreviewModal = (props: propsType): React.ReactElement => {
  const { asset, closePreview } = props;
  return (
    <Modal
      open={!!asset}
      destroyOnClose={true}
      title={asset?.name}
      width="65%"
      wrapClassName="asset-preview-modal"
      onCancel={closePreview}
      footer={null}
    >
      <div className="inner-wrapper">
        {asset && <AssetPreview asset={asset} />}
      </div>
    </Modal>
  );
};

export default AssetPreviewModal;
