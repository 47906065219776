import { ConttributorRoleStoreType } from "store/type";

export const initGlobalState: ConttributorRoleStoreType = {
  allContributorRoles: [],
  allContributorRolesMini: [],
  getContributorRoleLoading: false,
  updateContributorRoleLoading: false,
  saveContributorRoleLoading: false,
  deleteContributorRoleLoading: false
};
