import { AssetStoreType } from "store/type";

export const initGlobalState: AssetStoreType = {
  assetTypes: [],
  assetTypesMini: [],
  getAssetTypesLoading: false,
  saveAssetTypeLoading: false,
  updateAssetTypeLoading: false,
  deleteAssetTypeLoading: false,
};
