import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserUpdateType } from "common/type";
import axios from "./axios";
import { reArangeFilters } from "utils";

export const getCreatedUsers = createAsyncThunk("/user", async () => {
	try {
		const response = await axios.get("/user");
		return response.data;
	} catch (error: any) {
		console.log(error?.message, "error", error);
	}
});

export const updateCreatedUser = createAsyncThunk(
	"update/user/{id}",
	async (data: UserUpdateType, thunkAPI) => {
		try {
			const response = await axios.patch("/user/" + data.id, data);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const getUsersRecentCount = createAsyncThunk(
	"/user/recent-count",
	async (filters: any, thunkAPI) => {
		try {
			const queryFilters = reArangeFilters(filters);
			const response = await axios.get(`/user/recent-count${queryFilters}`);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const getUsersRecentList = createAsyncThunk(
	"/user/recent-list",
	async (filters: any, thunkAPI) => {
		try {
			const queryFilters = reArangeFilters(filters);
			const response = await axios.get(`/user/recent-list${queryFilters}`);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const getUserNotificationsList = createAsyncThunk(
	"/user/notifications-list",
	async (filters: any, thunkAPI) => {
		try {
			const queryFilters = reArangeFilters(filters);
			const response = await axios.get(`/notification${queryFilters}`);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const markReadNotification = createAsyncThunk(
	"/user/mark-read",
	async ({ id }: any, thunkAPI) => {
		try {
			await axios.patch(`/notification/mark-read/${id}`);
			return id;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);
