import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	getAssetTypes,
	saveAssetType,
	updateAssetType,
	deleteAssetType,
	getAssetTypesMini,
} from "services/assetType.service";
import { initGlobalState } from "./default";
import { ContType } from "../../common/type";
import { message } from "antd";

const globalSlice = createSlice({
	name: "global",
	initialState: initGlobalState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// Get All asset types
			.addCase(getAssetTypes.pending, (state) => {
				state.getAssetTypesLoading = true;
			})
			.addCase(getAssetTypes.fulfilled, (state, action) => {
				state.getAssetTypesLoading = false;
				state.assetTypes = action.payload;
			})
			.addCase(getAssetTypes.rejected, (state, action) => {
				state.getAssetTypesLoading = false;
			})

			// Save Asset type
			.addCase(saveAssetType.pending, (state) => {
				state.saveAssetTypeLoading = true;
			})
			.addCase(
				saveAssetType.fulfilled,
				(state, action: PayloadAction<ContType>) => {
					const testD: ContType = action.payload;
					state.assetTypes.unshift(testD);
				}
			)
			.addCase(saveAssetType.rejected, (state, action) => {
				state.saveAssetTypeLoading = false;
			})

			// Update Institute type
			.addCase(updateAssetType.pending, (state) => {
				state.updateAssetTypeLoading = true;
			})
			.addCase(
				updateAssetType.fulfilled,
				(state, action: PayloadAction<ContType>) => {
					state.updateAssetTypeLoading = false;
					message.success("Record updated successfully");
					const index = state.assetTypes.findIndex(
						(item) => item.id == action.payload.id
					);
					if (index > -1) {
						state.assetTypes[index] = action.payload;
					}
				}
			)
			.addCase(updateAssetType.rejected, (state, action) => {
				state.updateAssetTypeLoading = false;
			})

			// Delete Institute type
			.addCase(deleteAssetType.pending, (state) => {
				state.deleteAssetTypeLoading = true;
			})
			.addCase(deleteAssetType.fulfilled, (state, action) => {
				state.deleteAssetTypeLoading = false;
				message.success("Record deleted successfully");
				state.assetTypes = state.assetTypes.filter(
					(item) => item.id !== action.meta.arg
				);
			})
			.addCase(deleteAssetType.rejected, (state, action) => {
				state.deleteAssetTypeLoading = false;
			})

			// Get All asset types mini
			.addCase(getAssetTypesMini.fulfilled, (state, action) => {
				state.assetTypesMini = action.payload;
			});
	},
});

export const {} = globalSlice.actions;

export default globalSlice.reducer;
