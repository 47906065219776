import { RootState } from "store";
import { ReactElement, useEffect } from "react";
import { useAppSelector, useAppDispatch } from 'common/hooks/redux'
import { IComponent } from "../interface";
import { useNavigate } from "react-router-dom";
interface IAuthProvider extends IComponent {
  children: ReactElement;
}
const AuthGuard: React.FC<IAuthProvider> = (props: IAuthProvider): ReactElement => {
  const { children } = props;
  const { user, authenticationLoading } = useAppSelector((state: RootState) => state.auth)
  const navigate = useNavigate()
  // console.log(user, 'authenticationLoading =>', authenticationLoading);

  // TODO: uncomment to add Guard
  useEffect(() => {
    // console.log(!user, 'authenticationLoading =>', !authenticationLoading);
    // setTimeout(() => {
    // console.log(!user, 'authenticationLoading => 22222', !authenticationLoading);
    if (!user && !authenticationLoading) {
      navigate('/signIn')
    }
    // }, 2000);
  }, [user, authenticationLoading]);

  return children;
};

export default AuthGuard;