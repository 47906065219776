import { http, createConfig } from "wagmi";
import {
	mainnet, sepolia, polygon, goerli, bsc, syscoin, bscTestnet, gnosis, plinga,
	kroma, pulsechain, kcc, optimismGoerli, metachain, metisGoerli, karura,
	zoraTestnet, mintSepoliaTestnet, polygonMumbai, palm, linea, phoenix, optimism, flare, songbirdTestnet,
	songbird, cronos, rootstock, rootstockTestnet, telos, telosTestnet, lukso, darwinia, xdc,
	xdcTestnet, classic, okc, confluxESpaceTestnet, meter, meterTestnet, shibarium,
	flareTestnet, fuse, fuseSparknet, shimmer, manta, x1Testnet, xLayerTestnet, xLayer, bitTorrent,
	edgelessTestnet, opBNB, nexilix, oasys, fantom, fraxtal, zkSyncInMemoryNode, zkSyncLocalNode,
	boba, hedera, hederaTestnet, hederaPreviewnet, zkSyncSepoliaTestnet, filecoin,
	zkSync, cronosTestnet, pgn, areonNetworkTestnet, areonNetwork, flowTestnet,
	rollux, astar, mandala, flowPreviewnet, flowMainnet, acala, taraxa, taraxaTestnet, wanchain,
	modeTestnet, pulsechainV4, thunderTestnet, wanchainTestnet, klaytnBaobab, ektaTestnet, bscGreenfield,
	bitTorrentTestnet, confluxESpace, bronosTestnet, bronos, shimmerTestnet, metis, polygonZkEvm, wemix,
	wemixTestnet, coreDao, defichainEvm, defichainEvmTestnet, moonbeamDev, moonbeam, moonriver, moonbaseAlpha,
	localhost, polygonZkEvmTestnet, metachainIstanbul, tenet, gobi, reyaNetwork, lightlinkPhoenix, lightlinkPegasus,
	ekta, dogechain, telcoinTestnet, ronin, edgeware, saigon, edgewareTestnet, edgeless, kavaTestnet, kava, rss3Sepolia,
	kromaSepolia, polygonZkEvmCardona, fraxtalTestnet, inEVM, morphSepolia, filecoinHyperspace, crossbell, astarZkEVM,
	apexTestnet, fantomTestnet, oasisTestnet, merlin, luksoTestnet, liskSepolia, nexi, beam, iotex, iotexTestnet, mevTestnet,
	bxnTestnet, bxn, mantle, mantleTestnet, mantleSepoliaTestnet, bahamut, opBNBTestnet, syscoinTestnet, zetachain, zetachainAthensTestnet,
	eon, mev, cyber, canto, shardeumSphinx, klaytn, base, jbc, evmosTestnet, evmos, oortMainnetDev, gnosisChiado, haqqMainnet, bevmMainnet,
	fibo, rss3

} from "wagmi/chains";
import { injected, metaMask } from 'wagmi/connectors'


export const config = createConfig({
	chains: [mainnet,sepolia, polygon, goerli, bsc, syscoin, bscTestnet, gnosis, plinga, kroma,
		pulsechain, kcc, optimismGoerli, metachain, metisGoerli, karura, zoraTestnet,
		mintSepoliaTestnet, polygonMumbai, palm, linea, phoenix, optimism, flare, songbirdTestnet,
		songbird, cronos, rootstock, rootstockTestnet, telos, telosTestnet, lukso, darwinia, xdc,
		xdcTestnet, classic, okc, confluxESpaceTestnet, meter, meterTestnet, shibarium,
		flareTestnet, fuse, fuseSparknet, shimmer, manta, x1Testnet, xLayerTestnet, xLayer, bitTorrent,
		edgelessTestnet, opBNB, nexilix, oasys, fantom, fraxtal, zkSyncInMemoryNode, zkSyncLocalNode,
		boba, hedera, hederaTestnet, hederaPreviewnet, zkSyncSepoliaTestnet, filecoin,
		zkSync, cronosTestnet, pgn, areonNetworkTestnet, areonNetwork, flowTestnet,
		rollux, astar, mandala, flowPreviewnet, flowMainnet, acala, taraxa, taraxaTestnet, wanchain,
		modeTestnet, pulsechainV4, thunderTestnet, wanchainTestnet, klaytnBaobab, ektaTestnet, bscGreenfield,
		bitTorrentTestnet, confluxESpace, bronosTestnet, bronos, shimmerTestnet, metis, polygonZkEvm, wemix,
		wemixTestnet, coreDao, defichainEvm, defichainEvmTestnet, moonbeamDev, moonbeam, moonriver, moonbaseAlpha,
		localhost, polygonZkEvmTestnet, metachainIstanbul, tenet, gobi, reyaNetwork, lightlinkPhoenix, lightlinkPegasus,
		ekta, dogechain, telcoinTestnet, ronin, edgeware, saigon, edgewareTestnet, edgeless, kavaTestnet, kava, rss3Sepolia,
		kromaSepolia, polygonZkEvmCardona, fraxtalTestnet, inEVM, morphSepolia, filecoinHyperspace, crossbell, astarZkEVM,
		apexTestnet, fantomTestnet, oasisTestnet, merlin, luksoTestnet, liskSepolia, nexi, beam, iotex, iotexTestnet, mevTestnet,
		bxnTestnet, bxn, mantle, mantleTestnet, mantleSepoliaTestnet, bahamut, opBNBTestnet, syscoinTestnet, zetachain, zetachainAthensTestnet,
		eon, mev, cyber, canto, shardeumSphinx, klaytn, base, jbc, evmosTestnet, evmos, oortMainnetDev, gnosisChiado, haqqMainnet, bevmMainnet,
		fibo, rss3
	],
	connectors: [
    injected(),
    // metaMask(),
  ],
	transports: {
		[mainnet.id]: http(),
		[sepolia.id]: http(),
		[polygon.id]: http(),
		[goerli.id]: http(),
		[bsc.id]: http(),
		[bscTestnet.id]: http(),
		[gnosis.id]: http(),
		[plinga.id]: http(),
		[kroma.id]: http(),
		[pulsechain.id]: http(),
		[kcc.id]: http(),
		[optimismGoerli.id]: http(),
		[metachain.id]: http(),
		[metisGoerli.id]: http(),
		[zoraTestnet.id]: http(),
		[mintSepoliaTestnet.id]: http(),
		[polygonMumbai.id]: http(),
		[palm.id]: http(),
		[linea.id]: http(),
		[phoenix.id]: http(),
		[optimism.id]: http(),
		[flare.id]: http(),
		[songbirdTestnet.id]: http(),
		[songbird.id]: http(),
		[cronos.id]: http(),
		[rootstock.id]: http(),
		[rootstockTestnet.id]: http(),
		[telos.id]: http(),
		[telosTestnet.id]: http(),
		[lukso.id]: http(),
		[darwinia.id]: http(),
		[xdc.id]: http(),
		[xdcTestnet.id]: http(),
		[syscoin.id]: http(),
		[classic.id]: http(),
		[okc.id]: http(),
		[confluxESpaceTestnet.id]: http(),
		[meter.id]: http(),
		[meterTestnet.id]: http(),
		[shibarium.id]: http(),
		[flareTestnet.id]: http(),
		[fuse.id]: http(),
		[fuseSparknet.id]: http(),
		[shimmer.id]: http(),
		[manta.id]: http(),
		[x1Testnet.id]: http(),
		[xLayerTestnet.id]: http(),
		[xLayer.id]: http(),
		[bitTorrent.id]: http(),
		[edgelessTestnet.id]: http(),
		[opBNB.id]: http(),
		[nexilix.id]: http(),
		[oasys.id]: http(),
		[fantom.id]: http(),
		[fraxtal.id]: http(),
		[zkSyncInMemoryNode.id]: http(),
		[zkSyncLocalNode.id]: http(),
		[boba.id]: http(),
		[hedera.id]: http(),
		[hederaTestnet.id]: http(),
		[hederaPreviewnet.id]: http(),
		[zkSyncSepoliaTestnet.id]: http(),
		[filecoin.id]: http(),
		[zkSync.id]: http(),
		[cronosTestnet.id]: http(),
		[pgn.id]: http(),
		[areonNetworkTestnet.id]: http(),
		[areonNetwork.id]: http(),
		[flowTestnet.id]: http(),
		[rollux.id]: http(),
		[astar.id]: http(),
		[mandala.id]: http(),
		[flowPreviewnet.id]: http(),
		[flowMainnet.id]: http(),
		[acala.id]: http(),
		[taraxa.id]: http(),
		[taraxaTestnet.id]: http(),
		[wanchain.id]: http(),
		[modeTestnet.id]: http(),
		[pulsechainV4.id]: http(),
		[thunderTestnet.id]: http(),
		[wanchainTestnet.id]: http(),
		[klaytnBaobab.id]: http(),
		[ektaTestnet.id]: http(),
		[bscGreenfield.id]: http(),
		[bitTorrentTestnet.id]: http(),
		[confluxESpace.id]: http(),
		[bronosTestnet.id]: http(),
		[bronos.id]: http(),
		[shimmerTestnet.id]: http(),
		[metis.id]: http(),
		[polygonZkEvm.id]: http(),
		[wemix.id]: http(),
		[wemixTestnet.id]: http(),
		[coreDao.id]: http(),
		[defichainEvm.id]: http(),
		[defichainEvmTestnet.id]: http(),
		[moonbeamDev.id]: http(),
		[moonbeam.id]: http(),
		[moonriver.id]: http(),
		[moonbaseAlpha.id]: http(),
		[localhost.id]: http(),
		[polygonZkEvmTestnet.id]: http(),
		[metachainIstanbul.id]: http(),
		[tenet.id]: http(),
		[gobi.id]: http(),
		[reyaNetwork.id]: http(),
		[lightlinkPhoenix.id]: http(),
		[lightlinkPegasus.id]: http(),
		[ekta.id]: http(),
		[dogechain.id]: http(),
		[telcoinTestnet.id]: http(),
		[ronin.id]: http(),
		[edgeware.id]: http(),
		[saigon.id]: http(),
		[edgewareTestnet.id]: http(),
		[edgeless.id]: http(),
		[kavaTestnet.id]: http(),
		[kava.id]: http(),
		[rss3Sepolia.id]: http(),
		[kromaSepolia.id]: http(),
		[polygonZkEvmCardona.id]: http(),
		[fraxtalTestnet.id]: http(),
		[inEVM.id]: http(),
		[morphSepolia.id]: http(),
		[filecoinHyperspace.id]: http(),
		[crossbell.id]: http(),
		[astarZkEVM.id]: http(),
		[apexTestnet.id]: http(),
		[fantomTestnet.id]: http(),
		[oasisTestnet.id]: http(),
		[merlin.id]: http(),
		[luksoTestnet.id]: http(),
		[liskSepolia.id]: http(),
		[nexi.id]: http(),
		[beam.id]: http(),
		[iotex.id]: http(),
		[iotexTestnet.id]: http(),
		[mevTestnet.id]: http(),
		[bxnTestnet.id]: http(),
		[bxn.id]: http(),
		[mantle.id]: http(),
		[mantleTestnet.id]: http(),
		[mantleSepoliaTestnet.id]: http(),
		[bahamut.id]: http(),
		[opBNBTestnet.id]: http(),
		[syscoinTestnet.id]: http(),
		[zetachain.id]: http(),
		[zetachainAthensTestnet.id]: http(),
		[eon.id]: http(),
		[mev.id]: http(),
		[cyber.id]: http(),
		[canto.id]: http(),
		[shardeumSphinx.id]: http(),
		[klaytn.id]: http(),
		[base.id]: http(),
		[jbc.id]: http(),
		[evmosTestnet.id]: http(),
		[evmos.id]: http(),
		[oortMainnetDev.id]: http(),
		[gnosisChiado.id]: http(),
		[haqqMainnet.id]: http(),
		[bevmMainnet.id]: http(),
		[fibo.id]: http(),
		[rss3.id]: http(),

	},
});
