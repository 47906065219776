import poly from "assets/images/chainlogos/poly.png";
import binance from "assets/images/chainlogos/bn.png";
import ether from "assets/images/chainlogos/Ether.png";
import { NFT_ATTRIBUTES } from "common/type";

console.log('process.env.REACT_APP_API_BASE_URL =>',process.env.REACT_APP_API_BASE_URL);

export const SERVER_BASE_URL =
	process.env.REACT_APP_API_BASE_URL || "https://api-qa.onrekord.app";
	
export const API_BASE_URL = SERVER_BASE_URL + "/api";
// export const API_BASE_URL = "http://localhost:9000/api";

export const KYC_TOKEN_ADDRESS = "0x6e0FF10f4207f945E1892D9ca2B6B5C0A952efAE";
export const NFT_CONTRACT_ADDRESS =
	"0x0E0CfbBc44D6B05D74435B7b1f38190A611876B1";

export const KWIK_TRUST_WALLET_ADDRESS =
	"0x1D635535040Defd0767Fa062a9fBdF004dca5D6a";

export const PINATA_JWT_TOKEN =
	"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJiMTBjN2I0MS1lYmMxLTQ5NjctYTBmYy1hZDRjYTFhMjU4YWYiLCJlbWFpbCI6ImFyZmFuMzc5MDFAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJGUkExIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9LHsiaWQiOiJOWUMxIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6IjNhYjdhN2EwYTQ5YTUwMGQ3MjU5Iiwic2NvcGVkS2V5U2VjcmV0IjoiZGFjZDQyNDk1NWExYzE2YThmYjlkNGJhZTczYzRiZTA4Mjg0OWUzZjRlNzgzZmMwZWM4MzM3ODRiYmI3NzYwYSIsImlhdCI6MTcxNjQ2MzcwNn0.ANb0HGZdLKO7nP17UqpPot3eDthegDKirsJOb-ELau0";

export const COST_PER_IDENTITY_CHECK_KTX = 20;
export const COST_PER_MINT = 1;

export const KYC_STATUS = {
	CLEAR: "clear",
	pending: "pending",
	rejected: "rejected",
};

export const ROLES = {
	DEFAULT: "DEFAULT",
	ADMIN: "ADMIN",
};

export const NFT_STATUS = {
	DRAFT: "Draft",
	MINTED: "Minted",
};

export const REVENUE_STATUS = {
	PENDING: "PENDING",
	VALIDATED: "VALIDATED",
	EXPIRED: "EXPIRED",
	REJECTED: "REJECTED",
};

export const IMAGE_FILE_INPUTS: any = {
	coverImage: "image",
	profile_pic: "image",
};

export const NFT_FIELDS: NFT_ATTRIBUTES = {
	title: "Title",
	artist_name: "Artist/Band Name",
	artist_legal_name: "Artist Legal Name",
	artist_prs_no: "Artist Collection Society Number",
	tags: "Assets tags",
	description: "Asset Description",
	"asset.url": "Main Asset File",
	"coverImage.url": "Cover Image",
	"contributorRole.name": "Roles",
};

export const SUPPORTED_CHAINS = [
	{
		chain: "Metamask",
		logo: "meta",
	},
];

interface NETWORKSOBJType {
	[key: string]: {
		id: number;
		name: string;
		symbol: string;
		blockExplorerUrl: string;
		icon: string;
		logo: string;
		testnet: boolean;
		contractAddress: `0x${string}`;
		rpcUrl: {
			https: string;
			wss: string;
		};
		nameInCoingecko: string;
		currencyTokens: any;
	};
}

export const NETWORKS: NETWORKSOBJType = {
	// 1: {
	// 	id: 1,
	// 	name: "Ethereum - Mainnet",
	// 	symbol: "ETH",
	// 	blockExplorerUrl: "https://etherscan.io/",
	// 	icon: new URL(`${ether}`, import.meta.url).href,
	// 	// icon: import(`../assets/eterscan.png`),
	// 	logo: new URL(`${ether}`, import.meta.url).href,
	// 	rpcUrl: {
	// 		https: ``,
	// 		wss: "",
	// 	},
	// 	nameInCoingecko: "ethereum",
	// 	currencyTokens: {
	// 		// Note: addresses must be in lowerCase
	// 		"0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48": {
	// 			id: "USDC",
	// 			address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
	// 			symbol: "USDC",
	// 			image: {
	// 				thumb:
	// 					"https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
	// 				small:
	// 					"https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
	// 				large:
	// 					"https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png?1547042389",
	// 			},
	// 		},
	// 		"0xdac17f958d2ee523a2206206994597c13d831ec7": {
	// 			id: "USDT",
	// 			address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
	// 			symbol: "USDT",
	// 			image: {
	// 				thumb:
	// 					"https://assets.coingecko.com/coins/images/325/thumb/Tether.png?1668148663",
	// 				small:
	// 					"https://assets.coingecko.com/coins/images/325/small/Tether.png?1668148663",
	// 				large:
	// 					"https://assets.coingecko.com/coins/images/325/large/Tether.png?1668148663",
	// 			},
	// 		},
	// 		"0x0000000000000000000000000000000000000000": {
	// 			id: "ethereum",
	// 			address: "0x0000000000000000000000000000000000000000",
	// 			symbol: "ETH",
	// 			image: {
	// 				thumb:
	// 					"https://assets.coingecko.com/coins/images/279/thumb/ethereum.png?1595348880",
	// 				small:
	// 					"https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
	// 				large:
	// 					"https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880",
	// 			},
	// 		},
	// 	},
	// },
	// 11155111: {
	// 	id: 11155111,
	// 	name: "Sepolia Testnet",
	// 	symbol: "ETH",
	// 	blockExplorerUrl: "https://sepolia.etherscan.io/",
	// 	icon: new URL(`${ether}`, import.meta.url).href,
	// 	// icon: import(`../assets/eterscan.png`),
	// 	logo: new URL(`${ether}`, import.meta.url).href,
	// 	testnet: true,
	// 	contractAddress: "0x0E0CfbBc44D6B05D74435B7b1f38190A611876B1",
	// 	rpcUrl: {
	// 		https: "",
	// 		wss: "",
	// 	},
	// 	nameInCoingecko: "ethereum",
	// 	currencyTokens: {
	// 		Note: addresses must be in lowerCase
	// 		"0x779877a7b0d9e8603169ddbd7836e478b4624789": {
	// 			id: "chainlink",
	// 			address: "0x779877a7b0d9e8603169ddbd7836e478b4624789",
	// 			symbol: "KTX (LINK)",
	// 			image: {
	// 				thumb:
	// 					"https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700",
	// 				large:
	// 					"https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png?1547034700",
	// 			},
	// 		},
	// 		"0x779877A7B0D9E8603169DdbD7836e478b4624781": {
	// 			id: "chainlink",
	// 			address: "0x779877A7B0D9E8603169DdbD7836e478b4624781",
	// 			symbol: "KTX",
	// 			image: {
	// 				thumb:
	// 					"https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700",
	// 				large:
	// 					"https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png?1547034700",
	// 			},
	// 		},
	// 		"0x0000000000000000000000000000000000000000": {
	// 			id: "ethereum",
	// 			address: "0x0000000000000000000000000000000000000000",
	// 			symbol: "ETH",
	// 			image: {
	// 				thumb:
	// 					"https://assets.coingecko.com/coins/images/279/thumb/ethereum.png?1595348880",
	// 				large:
	// 					"https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880",
	// 			},
	// 		},
	// 	},
	// },

	137: {
		id: 137,
		name: "Polygon - Mainnet",
		symbol: "MATIC",
		blockExplorerUrl: "https://polygonscan.com/",
		icon: new URL(`${poly}`, import.meta.url).href, // TODO:change icon & logo
		rpcUrl: {
			https: ``,
			wss: "",
		},
		testnet: false,
		logo: new URL(`${poly}`, import.meta.url).href,
		nameInCoingecko: "polygon-pos",
		contractAddress: "0x38d58363c16423521C6B65aaABd8c318cD3c7633",
		currencyTokens: {
			// Note: addresses must be in lowerCase
			"0x60e6895184448f3e8ef509d083e3cc3ac31f82fd": {
				id: "ktx",
				address: "0x60E6895184448f3e8EF509D083e3cC3AC31F82Fd",
				symbol: "KTX",
				image: {
					thumb:
						"https://assets.coingecko.com/coins/images/14692/thumb/lokr.png?1648886932",
					small:
						"https://assets.coingecko.com/coins/images/14692/small/lokr.png?1648886932",
					large:
						"https://assets.coingecko.com/coins/images/14692/large/lokr.png?1648886932",
				},
			},
			// 	"0x2791bca1f2de4661ed88a30c99a7a9449aa84174": {
			// 		id: "USDC",
			// 		address: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
			// 		symbol: "USDC",
			// 		image: {
			// 			thumb:
			// 				"https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
			// 			small:
			// 				"https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
			// 			large:
			// 				"https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png?1547042389",
			// 		},
			// 	},
			// 	"0xc2132d05d31c914a87c6611c10748aeb04b58e8f": {
			// 		id: "USDT",
			// 		address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
			// 		symbol: "USDT",
			// 		image: {
			// 			thumb:
			// 				"https://assets.coingecko.com/coins/images/325/thumb/Tether.png?1668148663",
			// 			small:
			// 				"https://assets.coingecko.com/coins/images/325/small/Tether.png?1668148663",
			// 			large:
			// 				"https://assets.coingecko.com/coins/images/325/large/Tether.png?1668148663",
			// 		},
			// 	},
			// 	"0x0000000000000000000000000000000000000000": {
			// 		id: "matic-network",
			// 		address: "0x0000000000000000000000000000000000000000",
			// 		symbol: "MATIC",
			// 		image: {
			// 			thumb:
			// 				"https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png?1624446912",
			// 			small:
			// 				"https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912",
			// 			large:
			// 				"https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png?1624446912",
			// 		},
			// 	},
			// },
		},
		// 80001: {
		// 	id: 80001,
		// 	name: "Polygon Testnet",
		// 	symbol: "MATIC",
		// 	blockExplorerUrl: "https://mumbai.polygonscan.com/",
		// 	icon: new URL(`${poly}`, import.meta.url).href, // TODO:change icon & logo
		// 	testnet: true,
		// 	rpcUrl: {
		// 		https: "https://rpc-mumbai.maticvigil.com",
		// 		wss: "",
		// 	},
		// 	logo: new URL(`${poly}`, import.meta.url).href,
		// 	nameInCoingecko: "polygon-pos",
		// 	currencyTokens: {
		// Note: addresses must be in lowerCase
		// "0x326c977e6efc84e512bb9c30f76e30c160ed06fb": {
		// 	id: "chainlink",
		// 	address: "0x326c977e6efc84e512bb9c30f76e30c160ed06fb",
		// 	symbol: "LINK",
		// 	image: {
		// 		thumb:
		// 			"https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700",
		// 		small:
		// 			"https://assets.coingecko.com/coins/images/877/small/chainlink-new-logo.png?1547034700",
		// 		large:
		// 			"https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png?1547034700",
		// 	},
		// },
		// "0x60e6895184448f3e8ef509d083e3cc3ac31f82fd": {
		// 	id: "ktx",
		// 	address: "0x60e6895184448f3e8ef509d083e3cc3ac31f82fd",
		// 	symbol: "KTX",
		// 	image: {
		// 		thumb:
		// 			"https://assets.coingecko.com/coins/images/14692/thumb/lokr.png?1648886932",
		// 		small:
		// 			"https://assets.coingecko.com/coins/images/14692/small/lokr.png?1648886932",
		// 		large:
		// 			"https://assets.coingecko.com/coins/images/14692/large/lokr.png?1648886932",
		// 	},
		// },
		// "0x0000000000000000000000000000000000000000": {
		// 	id: "matic-network",
		// 	address: "0x0000000000000000000000000000000000000000",
		// 	symbol: "MATIC",
		// 	image: {
		// 		thumb:
		// 			"https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png?1624446912",
		// 		small:
		// 			"https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912",
		// 		large:
		// 			"https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png?1624446912",
		// 	},
		// },
		// },
	},
};
