import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	getContributorsRevenue,
	saveContributorsRevenue,
	updateContributorsRevenue,
	deleteContributorsRevenue,
	getContributorRevenueDetail,
	saveContributorsRevenueReminder,
	getContributionsRevenue,
} from "services/contributorRevenue.service";
import { initGlobalState } from "./default";
import { RevenueType, RevenueDetailType } from "../../common/type";
import { message } from "antd";
const globalSlice = createSlice({
	name: "global",
	initialState: initGlobalState,
	reducers: {
		toggleCommonRevenueLoading: (state, action: PayloadAction<boolean>) => {
			state.commonRevenueLoading = action.payload;
		},
		resetContributorsRevenueList: (state) => {
			state.allContributorRevenue = [];
		},
		toggleReminderLoading: (state, action: PayloadAction<any>) => {
			state.reminderLoading = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder

			// List-contributor-revenues
			.addCase(getContributorsRevenue.pending, (state) => {
				state.getContributorRevenueLoading = true;
			})
			.addCase(getContributorsRevenue.fulfilled, (state, action) => {
				state.getContributorRevenueLoading = false;
				state.allContributorRevenue = action.payload;
			})
			.addCase(getContributorsRevenue.rejected, (state, action) => {
				state.getContributorRevenueLoading = false;
			})

			// Save-contributor-revenue
			.addCase(saveContributorsRevenue.pending, (state) => {
				state.saveContributorRevenueLoading = true;
			})
			.addCase(
				saveContributorsRevenue.fulfilled,
				(state, action: PayloadAction<RevenueType>) => {
					const testD: RevenueType = action.payload;
					state.saveContributorRevenueLoading = false;
					state.allContributorRevenue.unshift(testD);
				}
			)
			.addCase(saveContributorsRevenue.rejected, (state, action) => {
				state.saveContributorRevenueLoading = false;
			})

			// Update-contributor-revenue
			.addCase(updateContributorsRevenue.pending, (state) => {
				state.updateContributorRevenueLoading = true;
			})
			.addCase(
				updateContributorsRevenue.fulfilled,
				(state, action: PayloadAction<RevenueType>) => {
					state.updateContributorRevenueLoading = false;
					message.success("Record updated successfully");
					state.revenueDetail = action.payload;
					const index = state.allContributorRevenue.findIndex(
						(item) => item.id == action.payload.id
					);
					if (index > -1) {
						state.allContributorRevenue[index] = action.payload;
					}
				}
			)
			.addCase(updateContributorsRevenue.rejected, (state, action) => {
				state.updateContributorRevenueLoading = false;
			})

			// Delete-contributor-revenue
			.addCase(deleteContributorsRevenue.pending, (state) => {
				state.deleteContributorRevenueLoading = true;
			})
			.addCase(deleteContributorsRevenue.fulfilled, (state, action) => {
				state.deleteContributorRevenueLoading = false;
				message.success("Record deleted successfully");
				state.allContributorRevenue = state.allContributorRevenue.filter(
					(item) => item.id !== action.meta.arg
				);
			})
			.addCase(deleteContributorsRevenue.rejected, (state, action) => {
				state.deleteContributorRevenueLoading = false;
			})

			// Get-revenue-detail
			.addCase(getContributorRevenueDetail.pending, (state) => {
				state.revenueDetailLoading = true;
				state.revenueDetail = {}
			})
			.addCase(
				getContributorRevenueDetail.fulfilled,
				(state, action: PayloadAction<RevenueDetailType>) => {
					state.revenueDetailLoading = false;
					state.revenueDetail = action.payload;
				}
			)
			.addCase(getContributorRevenueDetail.rejected, (state, action) => {
				state.revenueDetailLoading = false;
				state.revenueDetail = {}
			})

			// Save revenue reminder
			.addCase(saveContributorsRevenueReminder.pending, (state, action) => {
				state.reminderLoading = action.meta.arg.ids[0];
			})
			.addCase(saveContributorsRevenueReminder.fulfilled, (state) => {
				message.success("Email sent to notify the contributor");
				state.reminderLoading = null;
			})
			.addCase(saveContributorsRevenueReminder.rejected, (state) => {
				state.reminderLoading = null;
			})
		
			// List-contributor-revenues
			.addCase(getContributionsRevenue.pending, (state) => {
				state.revenueContributionsLoading = true;
			})
			.addCase(getContributionsRevenue.fulfilled, (state, action) => {
				state.revenueContributionsLoading = false;
				state.revenueContributions = action.payload;
			})
			.addCase(getContributionsRevenue.rejected, (state, action) => {
				state.revenueContributionsLoading = false;
			})
	},
});

export const {
	toggleCommonRevenueLoading,
	resetContributorsRevenueList,
	toggleReminderLoading,
} = globalSlice.actions;

export default globalSlice.reducer;
