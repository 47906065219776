import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import {
	requestUser,
	signIn,
	verifyOTP,
	signUp,
	requestKycToken,
	requestUserWithoutLoading,
} from "services/auth.service";
import { updateContributorProfile } from "services/contributor.service";
import { updateCreatedUser, getUsersRecentCount } from "services/createUser.service";
import { initGlobalState } from "./default";

const globalSlice = createSlice({
	name: "global",
	initialState: initGlobalState,
	reducers: {
		toggleLogoutSession: (state, action: PayloadAction<any>) => {
			state.user = action.payload;
			localStorage.removeItem("accessToken");
			message.success("You are logged out");
		},
		toggleLogOutModal: (state, action: PayloadAction<boolean>) => {
			state.logOutModal = action.payload;
		},
		toggleIsUserSideBarAdmin: (state, action: PayloadAction<any>) => {
			state.isUserSideBarAdmin = action.payload;
		},
		updateUserState: (state, action: PayloadAction<any>) => {
			state.user = { ...state.user, ...action.payload };
		},
	},
	extraReducers: (builder) => {
		builder
			// Sign-in
			.addCase(signIn.pending, (state) => {
				state.signInLoading = true;
			})
			.addCase(signIn.fulfilled, (state, action) => {
				message.success("OTP sent to your email address");
				state.signInLoading = false;
			})
			.addCase(signIn.rejected, (state, action) => {
				state.signInLoading = false;
			})

			// Sign-Up
			.addCase(signUp.pending, (state) => {
				state.signInLoading = true;
			})
			.addCase(signUp.fulfilled, (state, action) => {
				message.success("OTP sent to your email address");
				state.signInLoading = false;
			})
			.addCase(signUp.rejected, (state, action) => {
				state.signInLoading = false;
			})

			// Verify OTP
			.addCase(verifyOTP.pending, (state) => {
				state.verifyOTPLoading = true;
			})
			.addCase(verifyOTP.fulfilled, (state, action) => {
				state.verifyOTPLoading = false;
				state.user = action.payload.user;
				localStorage.setItem("accessToken", action.payload.token);
				message.success("You are logged in");
			})
			.addCase(verifyOTP.rejected, (state, action) => {
				state.verifyOTPLoading = false;
			})

			// Get User by Access Token
			.addCase(requestUser.pending, (state) => {
				state.authenticationLoading = true;
			})
			.addCase(requestUser.fulfilled, (state, action) => {
				state.authenticationLoading = false;
				state.user = action.payload;
			})
			.addCase(requestUser.rejected, (state, action) => {
				state.authenticationLoading = false;
			})

			.addCase(requestUserWithoutLoading.fulfilled, (state, action) => {
				state.user = action.payload;
			})

			// Update user contributor
			.addCase(updateContributorProfile.pending, (state) => {
				state.updateUserContrBtnLoading = true;
			})
			.addCase(updateContributorProfile.fulfilled, (state, action) => {
				state.updateUserContrBtnLoading = false;
				if (state.user) {
					state.user.contributor = action.payload;
				}
			})
			.addCase(updateContributorProfile.rejected, (state, action) => {
				state.updateUserContrBtnLoading = false;
			})

			// Update-user
			.addCase(updateCreatedUser.pending, (state) => {
				state.updateUserLoading = true;
			})
			.addCase(updateCreatedUser.fulfilled, (state, action) => {
				state.updateUserLoading = false;
				state.user = action.payload;
			})
			.addCase(updateCreatedUser.rejected, (state, action) => {
				state.updateUserLoading = false;
			});
	},
});

export const { toggleLogoutSession, toggleIsUserSideBarAdmin, toggleLogOutModal, updateUserState } =
	globalSlice.actions;

export default globalSlice.reducer;
