import { ContTypeStoreType } from "store/type";

export const initGlobalState: ContTypeStoreType = {
  allContributorTypes: [],
  allContributorTypesMini: [],
  getContributorTypeLoading: false,
  updateContributorTypeLoading: false,
  saveContributorTypeLoading: false,
  deleteContributorTypeLoading: false
};
