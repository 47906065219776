import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initCollectionSuperNFTState } from "./default";
import { SuperNFTType } from "common/type";
import { message } from "antd";

const collectionSuperNFTSlice = createSlice({
	name: "collectionSuperNFT",
	initialState: initCollectionSuperNFTState,
	reducers: {
		toggleCollectionStep: (state, action: PayloadAction<number>) => {
			state.collectionStep = action.payload;
		},
		toggleSelectNftModal: (state, action: PayloadAction<boolean>) => {
			state.selectSuperNftModal = action.payload;
		},
	},
});

export const { toggleCollectionStep, toggleSelectNftModal } = collectionSuperNFTSlice.actions;

export default collectionSuperNFTSlice.reducer;
