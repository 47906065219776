import { SuperNFTStoreType } from "store/type";

export const initSuperNFTState: SuperNFTStoreType = {
	allSuperNFTs: [],
	recentMintedNftCount: 0,
	recentMintedNftCountLoading: false,
	recentDraftedNftCount: 0,
	recentDraftedNftCountLoading: false,
	nftRecentList: [],
	superNftDetail: {
		step: 5,
		title: "",
		artist_name: "",
		artist_legal_name: "",
		artist_prs_no: "",
		description: "",
		tags: [],
		created_at: "",
		status: "",
		updated_at: "",
		contributors: [],
	},
	getNFTCount: [],
	getNFTCountLoading: false,
	getSuperNFTLoading: false,
	getSuperNFTsLoading: false,
	updateSuperNFTLoading: false,
	saveSuperNFTLoading: false,
	deleteSuperNFTLoading: false,
	superNftDetailLoading: false,
	nftRecentCountLoading: false,
	nftRecentListLoading: false,
	saveSuperNFTPreviousLoading: false,
	cloneNFTLoading: false,
	cloneNFTDetail: null,
	formSuperNFT: {
		title: "",
		artist_name: "",
		artist_legal_name: "",
		artist_prs_no: "",
		description: "",
		step: 0,
		tags: [],
	},
};
