import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { saveKycReminder } from "services/kyc.service";
import { initGlobalState } from "./default";
import { AdditionalAssetsType } from "../../common/type";
import { message } from "antd";

const globalSlice = createSlice({
	name: "global",
	initialState: initGlobalState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			// Save kyc reminder
			.addCase(saveKycReminder.pending, (state, action) => {
				state.kycReminderLoading = action.meta.arg.ids[0];
			})
			.addCase(saveKycReminder.fulfilled, (state) => {
				message.success("Email sent to notify the contributor");
				state.kycReminderLoading = null;
			})
			.addCase(saveKycReminder.rejected, (state) => {
				state.kycReminderLoading = null;
			});
	},
});

export const {} = globalSlice.actions;

export default globalSlice.reducer;
