import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";
// import { reArangeFilters } from "@/utils/helper"

// export const signIn = async (data) => {
//   try {
//     const response = await axios.post('/auth/login', data);
//     return response.data;
//   } catch (error) { }
// }

export const signIn = createAsyncThunk(
	"auth/signIn",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.post("/auth/login", data);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			// You can handle errors here if needed
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const signUp = createAsyncThunk(
	"auth/register",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.post("/auth/register", data);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			// You can handle errors here if needed
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const verifyOTP = createAsyncThunk(
	"auth/login/otp",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.post("/auth/login/otp", data);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			// You can handle errors here if needed
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const requestUser = createAsyncThunk("request/user", async () => {
	try {
		const response = await axios.get("/request/user");
		return response.data;
	} catch (error: any) {
		console.log(error?.message, "error", error);
		// You can handle errors here if needed
		// return thunkAPI.rejectWithValue({ error: error.message });
	}
});

export const requestUserWithoutLoading = createAsyncThunk(
	"request/user/without/loading",
	async () => {
		try {
			const response = await axios.get("/request/user");
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			// You can handle errors here if needed
			// return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const requestKycToken = createAsyncThunk(
	"request/kyc/client",
	async (clientId: any, thunkAPI) => {
		try {
			const response = await axios.get("/kyc/client/" + clientId);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			// You can handle errors here if needed
			// return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const createKycCheck = createAsyncThunk(
	"check/create",
	async (payload: any, thunkAPI) => {
		try {
			const response = await axios.post("/kyc/check/create", payload);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			// You can handle errors here if needed
			// return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const updateCredits = createAsyncThunk(
	"/credits/update",
	async (payload: any, thunkAPI) => {
		try {
			const response = await axios.patch("/user/credits/update", payload);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			// You can handle errors here if needed
			// return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

