import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContType } from "common/type";
import axios from "./axios";

export const getAssetTypes = createAsyncThunk('all/asset/type/all', async () => {
  try {
    const response = await axios.get('/asset/type/all')
    return response.data;
  } catch (error: any) {
    console.log(error?.message,'error', error);
  }
});

export const saveAssetType = createAsyncThunk('save/asset/type/', async (data: any, thunkAPI) => {
  try {
    const response = await axios.post('/asset/type/',data)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const updateAssetType = createAsyncThunk('update/asset/type/', async (data: ContType, thunkAPI) => {
  try {
    const response = await axios.patch('/asset/type/'+data.id,data)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const deleteAssetType = createAsyncThunk('delete/asset/type/', async (id: number|null, thunkAPI) => {
  try {
    const response = await axios.delete('/asset/type/'+id)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getAssetTypesMini = createAsyncThunk('all/asset/type/all/mini', async () => {
  try {
    const response = await axios.get('/asset/type/all/mini')
    return response.data;
  } catch (error: any) {
    console.log(error?.message,'error', error);
  }
});