import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

export const saveKycReminder = createAsyncThunk(
	"update/kyc/reminder",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.post(
				"/kyc/reminder",
				data
			);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);
