import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	getMyFiles,
	saveMyFiles,
	getRecentFilesCount,
	getAssetDetail,
  deleteAsset
} from "services/myFiles.service";
import { initGlobalState } from "./default";
import { MyFilesType } from "../../common/type";

const globalSlice = createSlice({
	name: "global",
	initialState: initGlobalState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			// Get All Uploaded Assets
			.addCase(getMyFiles.pending, (state) => {
				state.getMyFilesLoading = true;
			})
			.addCase(getMyFiles.fulfilled, (state, action) => {
				state.getMyFilesLoading = false;
				state.MyFiles = action.payload;
			})
			.addCase(getMyFiles.rejected, (state, action) => {
				state.getMyFilesLoading = false;
			})

			// Delete my file
			.addCase(deleteAsset.fulfilled, (state, action) => {
				state.MyFiles = state.MyFiles.filter(item => item.id !== action.meta.arg)
				state.RecentFileCount = state.RecentFileCount.filter(item => item.id !== action.meta.arg)
			})

			// Save Uploaded Assets
			.addCase(saveMyFiles.pending, (state) => {
				state.saveMyFilesLoading = true;
			})
			.addCase(
				saveMyFiles.fulfilled,
				(state, action: PayloadAction<MyFilesType>) => {
					const testD: MyFilesType = action.payload;
					state.saveMyFilesLoading = false;
					state.MyFiles.unshift(testD);
				}
			)
			.addCase(saveMyFiles.rejected, (state, action) => {
				state.saveMyFilesLoading = false;
			})

			// Get Recent,s Count
			.addCase(getRecentFilesCount.pending, (state) => {
				state.getRecentFilesCountLoading = true;
			})
			.addCase(getRecentFilesCount.fulfilled, (state, action) => {
				state.getRecentFilesCountLoading = false;
				state.RecentFileCount = action.payload;
			})
			.addCase(getRecentFilesCount.rejected, (state, action) => {
				state.getRecentFilesCountLoading = false;
			})
			
			// Get Main Asset Detail
			.addCase(getAssetDetail.pending, (state) => {
			state.mainAssetDetailLoading = true;
			})
			.addCase(getAssetDetail.fulfilled, (state, action) => {
			state.mainAssetDetailLoading = false;
			state.mainAssetDetail = action.payload
			})
			.addCase(getAssetDetail.rejected, (state, action) => {
			state.mainAssetDetailLoading = false;
			})
	},
});

export const {} = globalSlice.actions;

export default globalSlice.reducer;
