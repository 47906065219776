import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	getSuperNFT,
	getSuperNFTs,
	saveSuperNFT,
	deleteSuperNFT,
	updateSuperNFT,
	getSuperNFTDetail,
	getRecentNFTCount,
	mintTheSuperNFT,
	getRecentMintedNftCount,
	getRecentDraftedNftCount,
	getRecentNftList,
	updateSuperNFTPreviousButton,
	getCloneNFTDetail,
} from "services/superNFT.service";
import { initSuperNFTState } from "./default";
import { SuperNFTType } from "common/type";
import { message } from "antd";
const superNFTSlice = createSlice({
	name: "superNFT",
	initialState: initSuperNFTState,
	reducers: {
		updateSuperNftForm: (state, action: PayloadAction<any>) => {
			state.formSuperNFT = { ...state.formSuperNFT, ...action.payload };
		},
		resetSuperNftForm: (state) => {
			state.formSuperNFT = { ...initSuperNFTState.formSuperNFT };
		},
	},
	extraReducers: (builder) => {
		builder
			// List-SuperNFTs
			.addCase(getSuperNFTs.pending, (state) => {
				state.getSuperNFTsLoading = true;
			})
			.addCase(getSuperNFTs.fulfilled, (state, action) => {
				state.getSuperNFTsLoading = false;
				state.allSuperNFTs = action.payload;
			})
			.addCase(getSuperNFTs.rejected, (state, action) => {
				state.getSuperNFTsLoading = false;
			})

			.addCase(getSuperNFT.pending, (state) => {
				state.getSuperNFTLoading = true;
			})
			.addCase(getSuperNFT.fulfilled, (state, action) => {
				state.getSuperNFTLoading = false;
				state.formSuperNFT = action.payload;
			})
			.addCase(getSuperNFT.rejected, (state, action) => {
				state.getSuperNFTLoading = false;
			})

			.addCase(getSuperNFTDetail.pending, (state) => {
				state.superNftDetailLoading = true;
			})
			.addCase(getSuperNFTDetail.fulfilled, (state, action) => {
				state.superNftDetailLoading = false;
				state.superNftDetail = action.payload;
			})
			.addCase(getSuperNFTDetail.rejected, (state, action) => {
				state.superNftDetailLoading = false;
			})

			// Save-SuperNFT
			.addCase(saveSuperNFT.pending, (state) => {
				state.saveSuperNFTLoading = true;
			})
			.addCase(
				saveSuperNFT.fulfilled,
				(state, action: PayloadAction<SuperNFTType>) => {
					const testD: SuperNFTType = action.payload;
					state.saveSuperNFTLoading = false;
					state.formSuperNFT = testD;
					// state.allSuperNFTs.unshift(testD);
				}
			)
			.addCase(saveSuperNFT.rejected, (state, action) => {
				state.saveSuperNFTLoading = false;
			})

			// Update-SuperNFT
			.addCase(updateSuperNFT.pending, (state) => {
				state.saveSuperNFTLoading = true;
			})
			.addCase(
				updateSuperNFT.fulfilled,
				(state, action: PayloadAction<SuperNFTType>) => {
					state.saveSuperNFTLoading = false;
					message.success("Record updated successfully");
					state.formSuperNFT = action.payload;
				}
			)
			.addCase(updateSuperNFT.rejected, (state, action) => {
				state.saveSuperNFTLoading = false;
			})

			// Update-SuperNFT-throught-previous-button
			.addCase(updateSuperNFTPreviousButton.pending, (state) => {
				state.saveSuperNFTPreviousLoading = true;
			})
			.addCase(
				updateSuperNFTPreviousButton.fulfilled,
				(state, action: PayloadAction<SuperNFTType>) => {
					state.saveSuperNFTPreviousLoading = false;
					message.success("Record updated successfully");
					state.formSuperNFT = action.payload;
				}
			)
			.addCase(updateSuperNFTPreviousButton.rejected, (state, action) => {
				state.saveSuperNFTPreviousLoading = false;
			})

			// Mint-SuperNFT
			.addCase(mintTheSuperNFT.pending, (state) => {
				state.saveSuperNFTLoading = true;
			})
			.addCase(
				mintTheSuperNFT.fulfilled,
				(state, action: PayloadAction<SuperNFTType>) => {
					state.saveSuperNFTLoading = false;
					message.success("Record updated successfully");
					state.formSuperNFT = action.payload;
				}
			)
			.addCase(mintTheSuperNFT.rejected, (state, action) => {
				state.saveSuperNFTLoading = false;
			})

			// Delete-SuperNFT
			.addCase(deleteSuperNFT.pending, (state) => {
				state.deleteSuperNFTLoading = true;
			})
			.addCase(deleteSuperNFT.fulfilled, (state, action) => {
				state.deleteSuperNFTLoading = false;
				message.success("Record deleted successfully");
				state.allSuperNFTs = state.allSuperNFTs.filter(
					(item) => item.id !== action.meta.arg
				);
				state.getNFTCount = state.getNFTCount.filter(
					(item) => item.id !== action.meta.arg
				);
			})
			.addCase(deleteSuperNFT.rejected, (state, action) => {
				state.deleteSuperNFTLoading = false;
			})

			// Get NFT,s Count
			.addCase(getRecentNFTCount.pending, (state) => {
				state.getNFTCountLoading = true;
			})
			.addCase(getRecentNFTCount.fulfilled, (state, action) => {
				state.getNFTCountLoading = false;
				state.getNFTCount = action.payload;
			})
			.addCase(getRecentNFTCount.rejected, (state, action) => {
				state.getSuperNFTLoading = false;
			})

			// Get Nft recent count
			.addCase(getRecentDraftedNftCount.pending, (state) => {
				state.recentDraftedNftCountLoading = true;
			})
			.addCase(getRecentDraftedNftCount.fulfilled, (state, action) => {
				state.recentDraftedNftCountLoading = false;
				state.recentDraftedNftCount = action.payload;
			})
			.addCase(getRecentDraftedNftCount.rejected, (state, action) => {
				state.recentDraftedNftCountLoading = false;
			})

			// Get Nft recent count
			.addCase(getRecentMintedNftCount.pending, (state) => {
				state.recentMintedNftCountLoading = true;
			})
			.addCase(getRecentMintedNftCount.fulfilled, (state, action) => {
				state.recentMintedNftCountLoading = false;
				state.recentMintedNftCount = action.payload;
			})
			.addCase(getRecentMintedNftCount.rejected, (state, action) => {
				state.recentMintedNftCountLoading = false;
			})

			// Get Nft recent list
			.addCase(getRecentNftList.pending, (state) => {
				state.nftRecentListLoading = true;
			})
			.addCase(getRecentNftList.fulfilled, (state, action) => {
				state.nftRecentListLoading = false;
				state.nftRecentList = action.payload;
			})
			.addCase(getRecentNftList.rejected, (state, action) => {
				state.nftRecentListLoading = false;
			})
		
			// Clone nft loading
			.addCase(getCloneNFTDetail.pending, (state) => {
				state.cloneNFTLoading = true;
			})

			.addCase(
				getCloneNFTDetail.fulfilled,
				(state, action: PayloadAction<any>) => {
					const detail: any = action.payload;
					state.cloneNFTLoading = false;
					state.allSuperNFTs.unshift(detail);
					message.success("NFT cloned successfully");
				}
			)
			.addCase(getCloneNFTDetail.rejected, (state, action) => {
				state.cloneNFTLoading = false;
			});
	},
});

export const { updateSuperNftForm, resetSuperNftForm } = superNFTSlice.actions;

export default superNFTSlice.reducer;
