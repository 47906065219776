import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";
import { reArangeFilters } from "utils";

export const getContributorsRevenue = createAsyncThunk(
	"get/contributorRevenue",
	async (filters: any, thunkAPI) => {
		try {
			const queryFilters = reArangeFilters(filters);
			const response = await axios.get(`/contributorRevenue${queryFilters}`);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const saveContributorsRevenue = createAsyncThunk(
	"save/contributorRevenue",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.post("/contributorRevenue", data);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const updateContributorsRevenue = createAsyncThunk(
	"update/contributorRevenue/{id}",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.patch(
				"/contributorRevenue/" + data.id,
				data
			);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const deleteContributorsRevenue = createAsyncThunk(
	"delete/contributorRevenue/{id}",
	async (id: number | null, thunkAPI) => {
		try {
			const response = await axios.delete("/contributorRevenue/" + id);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const saveContributorsRevenueReminder = createAsyncThunk(
	"update/contributorRevenue/reminder",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.post(
				"/contributorRevenue/reminder",
				data
			);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const getContributorRevenueDetail = createAsyncThunk(
	"/api/contributorRevenue/{id}",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.get(
				"/contributorRevenue/" + data
			);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const getContributionsRevenue = createAsyncThunk(
	"get/contributionsRevenue",
	async (filters: any, thunkAPI) => {
		try {
			const queryFilters = reArangeFilters(filters);
			const response = await axios.get(`/contributorRevenue${queryFilters}`);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);
