import React, { useEffect, useState } from "react";
import { Button, Modal, Col, Row, Form, Upload, Input, Select } from "antd";
import "./modals.scss";
import { InboxOutlined } from "@ant-design/icons";
import { getMyFiles, saveMyFiles } from "services/myFiles.service";
import { useAppSelector, useAppDispatch } from "common/hooks/redux";
import { RootState } from "store/index";
import { toggleAddNewFileModal } from "store/global";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import { getAssetTypesMini } from "services/assetType.service";

/**
 * @param {UploadType} props
 */

const AddNewFileModal: React.FC = (): React.ReactElement => {
	const [form] = Form.useForm();
	const dispatch = useAppDispatch();
	const AddNewFileModal = useAppSelector(
		(state: RootState) => state.global.addNewFileModal
	);
	const { assetTypesMini } = useAppSelector(
		(state: RootState) => state.assetTypes
	);
	const { saveMyFilesLoading } = useAppSelector(
		(state: RootState) => state.myFiles
	);

	const [file, setFile] = useState<UploadFile | null>(null);
	const { Dragger } = Upload;
	useEffect(() => {
		dispatch(getMyFiles());
		dispatch(getAssetTypesMini());
	}, []);

	const onFinish = (values: any) => {
		console.log("Success:", values);
		onSubmit(values, () => {
			dispatch(toggleAddNewFileModal(false));
		});
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
	};

	const onSubmit = async (values: any, callback: any) => {
		values.file = file;
		dispatch(saveMyFiles(values)).then((res) => {
			console.log("res res=>", res);
			setFile(null);
			callback();
		});
	};

	type FieldType = {
		name?: string;
		assetTypeId?: number;
		file?: any;
	};

	function toggleModal(visible: boolean = false) {
		dispatch(toggleAddNewFileModal(visible));
	}

	function closeModal() {
		toggleModal(false)
		setFile(null);
	}

	const props: UploadProps = {
		multiple: false,
		onRemove: (file) => {
			setFile(null);
		},
		beforeUpload: (file) => {
			setFile(file);
			return false;
		},
	};

	const sortedTypes = [...assetTypesMini].sort((a: any, b: any) => {
		const nameA = a.name.toLowerCase();
		const nameB = b.name.toLowerCase();
		if (nameA < nameB) return -1;
		if (nameA > nameB) return 1;
		return 0;
	});

	return (
		<Modal
			open={AddNewFileModal}
			title="Choose an asset"
			width="auto"
			destroyOnClose={true}
			afterClose={() => form?.resetFields()}
			style={{ maxWidth: "1147px" }}
			wrapClassName="upload-assets"
			onCancel={() => closeModal()}
			footer={[
				<Button key="back" onClick={() => closeModal()}>
					Cancel
				</Button>,
				<Button
					type="primary"
					onClick={form.submit}
					loading={saveMyFilesLoading}
				>
					Submit
				</Button>,
			]}
		>
			<Form
				form={form}
				name="add-new-file"
				layout="vertical"
				className="upload-assets"
				style={{}}
				initialValues={{ remember: true }}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Row gutter={24}>
					<Col className="gutter-row" span={24}>
						<Form.Item<FieldType>
							label="Name"
							name="name"
							rules={[
								{ required: true, message: "Please enter name" },
								{ whitespace: true, message: "Please enter valid data." },
							]}
						>
							<Input placeholder="Please enter name" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={24}>
					<Col className="gutter-row" span={24}>
						<Form.Item<FieldType>
							label="Choose asset type"
							name="assetTypeId"
							rules={[{ required: true, message: "Please choose type" }]}
						>
							<Select
								placeholder="asset type"
								options={sortedTypes?.map(
									(item: { name: any; id: any }) => ({
										label: item.name,
										value: item.id,
									})
								)}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={24}>
					<Col className="gutter-row" span={24}>
						<Form.Item<FieldType>
							label="Choose asset"
							name="file"
							rules={[
								{ required: true, message: "Please choose Asset" },
								({ getFieldValue }) => ({
									validator(_, value) {
										if (value?.file?.type === "image/tiff") {
											return Promise.reject(
												new Error("Unsupported File format!")
											);
										}
										return Promise.resolve();
									},
								}),
							]}
						>
							<Dragger {...props} className="drag-upload">
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-text">
									Browse or drag files here to upload
								</p>
								<p className="ant-upload-hint">Support for a single upload.</p>
								<p>{file && file.name}</p>
							</Dragger>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

export default AddNewFileModal;
