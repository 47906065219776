import { AdditionalAssetsStoreType } from "store/type";

export const initGlobalState: AdditionalAssetsStoreType = {
  allAdditionalAssets: [],
  additionalContributions: [],
  additionalAssetDetail: {},
  getAdditionalAssetsLoading: false,
  saveAdditionalAssetsLoading: false,
  updateAdditionalAssetsLoading: false,
  deleteAdditionalAssetsLoading: false,
  additionalReminderLoading: null,
  additionalAssetDetailLoading: false,
  additionalContributionsLoading: false,
};
