import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	getAssetTags,
	saveAssetTags,
	updateAssetTags,
	deleteAssetTags,
	getAssetTagsMini,
} from "services/assetTags.service";
import { initGlobalState } from "./default";
import { ContType } from "../../common/type";
import { message } from "antd";

const globalSlice = createSlice({
	name: "global",
	initialState: initGlobalState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// Get All tags
			.addCase(getAssetTags.pending, (state) => {
				state.getAssetTagsLoading = true;
			})
			.addCase(getAssetTags.fulfilled, (state, action) => {
				state.getAssetTagsLoading = false;
				state.assetTags = action.payload;
			})
			.addCase(getAssetTags.rejected, (state, action) => {
				state.getAssetTagsLoading = false;
			})

			// Save Asset tags
			.addCase(saveAssetTags.pending, (state) => {
				state.saveAssetTagsLoading = true;
			})
			.addCase(
				saveAssetTags.fulfilled,
				(state, action: PayloadAction<ContType>) => {
					const testD: ContType = action.payload;
					state.assetTags.unshift(testD);
				}
			)
			.addCase(saveAssetTags.rejected, (state, action) => {
				state.saveAssetTagsLoading = false;
			})

			// Update Institute type
			.addCase(updateAssetTags.pending, (state) => {
				state.updateAssetTagsLoading = true;
			})
			.addCase(
				updateAssetTags.fulfilled,
				(state, action: PayloadAction<ContType>) => {
					state.updateAssetTagsLoading = false;
					message.success("Record updated successfully");
					const index = state.assetTags.findIndex(
						(item) => item.id == action.payload.id
					);
					if (index > -1) {
						state.assetTags[index] = action.payload;
					}
				}
			)
			.addCase(updateAssetTags.rejected, (state, action) => {
				state.updateAssetTagsLoading = false;
			})

			// Delete Institute type
			.addCase(deleteAssetTags.pending, (state) => {
				state.deleteAssetTagsLoading = true;
			})
			.addCase(deleteAssetTags.fulfilled, (state, action) => {
				state.deleteAssetTagsLoading = false;
				message.success("Record deleted successfully");
				state.assetTags = state.assetTags.filter(
					(item) => item.id !== action.meta.arg
				);
			})
			.addCase(deleteAssetTags.rejected, (state, action) => {
				state.deleteAssetTagsLoading = false;
			})

			// Get All asset roles mini
			.addCase(getAssetTagsMini.fulfilled, (state, action) => {
				state.allAssetTagsMini = action.payload;
			});
	},
});

export const {} = globalSlice.actions;

export default globalSlice.reducer;
