import React, { useEffect, useState } from "react";
import {
  Button, Modal, Col, Row, Image, Tabs, List,
  TabsProps, Avatar, Spin, Tooltip
} from "antd";
import "./modals.scss";
import { EyeFilled, InfoCircleOutlined, QrcodeOutlined, ExpandAltOutlined } from "@ant-design/icons";
import { useAppSelector, useAppDispatch } from "common/hooks/redux";
import { RootState } from "store/index";
import { toggleNftPreviewModal } from "store/global";
import { getSuperNFTDetail } from "services/superNFT.service";
import { calculateFileSize, assetPreview, assetTypeIcon } from "utils";
import AssetPreviewModal from "common/components/Modals/AssetPreviewModal";
import moment from "moment";
import setting from "assets/images/setting.svg";
import users from "assets/images/users.svg";
import { NFT_STATUS } from "../../constants";
import { useNavigate } from 'react-router-dom';

type PreviewType = {
  id?: number | undefined;
};

/**
 * @param {PreviewType} props
 */

const NftPreviewModal: React.FC<PreviewType> = (
  props: PreviewType
): React.ReactElement => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const nftPreviewModal = useAppSelector(
    (state: RootState) => state.global.nftPreviewModal
  );
  const [preview, setPreview] = useState<any>(null);
  const { superNftDetail, saveSuperNFTLoading, superNftDetailLoading } =
    useAppSelector((state: RootState) => state.superNFT);

  const totalLength = (superNftDetail?.contributors?.length ?? 0) + (superNftDetail?.additionalAssets?.length ?? 0);
  const totalAssetLength = (superNftDetail?.asset?.length ?? 1) + (superNftDetail?.additionalAssets?.length ?? 0);

  function toggleModal(visible: any = null) {
    dispatch(toggleNftPreviewModal(visible));
  }
  function closePreview() {
    setPreview(null);
  }
  const data = [
    {
      title: "File name",
      content: superNftDetail.title,
    },
    // {
    //   title: 'File hash',
    //   content: '128gcnaksnlb'
    // },
    {
      title: "Contributors",
      content: totalLength,
    },
    {
      title: "Uploaded date",
      content: moment(superNftDetail.created_at).format("MMM Do, YYYY"),
    },
    {
      title: "Size",
      content: calculateFileSize(superNftDetail.asset?.size),
    },
    {
      title: "Price",
      content: `${superNftDetail.price} $`,
    },
  ];

  function handlePreview(item: any) {
    setPreview(item.asset);
  }
  function handleMainPreview(item: any) {
    setPreview(item);
  }

  const detailPage = () => {
    navigate('/nft-preview-detail/' + superNftDetail.id);
    toggleModal(false)
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Details`,
      icon: <InfoCircleOutlined />,
      children: (
        <List
          size="small"
          bordered
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <div className="item-wrapper">
                <h3>{item.title}</h3>
                <span className="file-name">
                  {item.title == "File hash" && (
                    <Image
                      src={setting}
                      preview={false}
                      style={{ paddingRight: "8px" }}
                    />
                  )}
                  {item.title == "Contributors" && (
                    <Image
                      src={users}
                      preview={false}
                      style={{ paddingRight: "8px" }}
                    />
                  )}
                  {item.content}
                </span>
              </div>
            </List.Item>
          )}
        />
      ),
    },
    {
      key: "2",
      label: `Assets (${totalAssetLength})`,
      icon: <QrcodeOutlined />,
      children: (
        <div className="details">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <hr style={{ flex: 1, marginRight: '1em', height: 0, borderTop: '1px solid #FFFFFF' }} />
            <span style={{ margin: '0 1em', color: '#FFFFFF' }}>Main Asset</span>
            <hr style={{ flex: 1, marginLeft: '1em', height: 0, borderTop: '1px solid #FFFFFF' }} />
          </div>
          <List
            size="small"
            bordered
            dataSource={[superNftDetail?.asset]}
            renderItem={(item: any) =>
              <List.Item className="details-list" actions={[<EyeFilled onClick={() => handleMainPreview(item)} />]}>
                <List.Item.Meta avatar={<Avatar src={require(`assets/images/filetypes/${assetTypeIcon(item?.mimetype)}`)} />} />
                <div className="item-wrapper">
                  <Tooltip title={item?.name}>
                    <span>{item?.name}</span>
                  </Tooltip>
                </div>
              </List.Item>
            }
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <hr style={{ flex: 1, marginRight: '1em', height: 0, borderTop: '1px solid #FFFFFF' }} />
            <span style={{ margin: '0 1em', color: '#FFFFFF' }}>Additional Asset</span>
            <hr style={{ flex: 1, marginLeft: '1em', height: 0, borderTop: '1px solid #FFFFFF' }} />
          </div>
          <List
            size="small"
            bordered
            dataSource={superNftDetail.additionalAssets}
            className="add-asset-list"
            renderItem={(item: any) => (
              <List.Item className="details-list" actions={[<EyeFilled onClick={() => handlePreview(item)} />]}>
                <List.Item.Meta
                  avatar={<Avatar src={require(`assets/images/filetypes/${assetTypeIcon(item.asset?.mimetype)}`)} />}
                />
                <div className="item-wrapper">
                  <Tooltip title={item.asset?.name}>
                    <span>{item.asset?.name}</span>
                  </Tooltip>
                </div>
              </List.Item>
            )}
          />
          <AssetPreviewModal asset={preview} closePreview={closePreview} />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (nftPreviewModal) {
      dispatch(getSuperNFTDetail(nftPreviewModal));
    }
  }, [nftPreviewModal]);

  return (
    <Modal
      open={!!nftPreviewModal}
      title="SuperNFT view"
      width="auto"
      wrapClassName="preview-nft"
      onCancel={() => toggleModal(null)}
      style={{ maxWidth: "909px" }}
      footer={[
        <Tooltip title="coming soon">
          <Button key="back" disabled>
            Transfer ownership
          </Button>
        </Tooltip>,

        superNftDetail.status === NFT_STATUS.DRAFT ? (
          ""
        ) : (
          <Tooltip title="View more details">
            <Button key="view-blockchain" type="primary" onClick={detailPage}>
              {/* <ExpandAltOutlined /> */}
              View more details
            </Button>
          </Tooltip>
        )
      ]}
    >
      {superNftDetailLoading ? (<Spin size="large" style={{ display: 'block' }} />) : (
        <Row gutter={24}>
          <Col xl={10} lg={10} md={10} sm={10} xs={10}>
            <div className="cover-container">
              <Image
                // width="100%"
                max-height="450px"
                src={assetPreview(superNftDetail.coverImage?.url, superNftDetail.coverImage?.mimetype)}
                style={{ borderRadius: '8px' }}
                preview={false}
                className="cover-image"
              />
            </div>
          </Col>
          <Col xl={14} lg={14} md={14} sm={14} xs={14}>
            <div className="right-wrapper">
              <div className="title-wrap">
                <h1>{superNftDetail.title}</h1>
                <p>by {superNftDetail.artist_name}</p>
              </div>
              <Tabs defaultActiveKey="1" items={items} />
            </div>
          </Col>
        </Row>
      )}

    </Modal>
  );
};

export default NftPreviewModal;
