import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	getAdditionalAssets,
	saveAdditionalAssets,
	getAdditionalAssetDetail,
	updateAdditionalAssets,
	deleteAdditionalAssets,
	saveAdditionalAssetReminder,
	getAdditionalContributions,
} from "services/additionalAssets.service";
import { initGlobalState } from "./default";
import { AdditionalAssetsType } from "../../common/type";
import { message } from "antd";

const globalSlice = createSlice({
	name: "global",
	initialState: initGlobalState,
	reducers: {
		resetAdditionalAssets: (state) => {
			state.allAdditionalAssets = [];
		},
	},
	extraReducers: (builder) => {
		builder
			// Get All Additional Assets
			.addCase(getAdditionalAssets.pending, (state) => {
				state.getAdditionalAssetsLoading = true;
			})
			.addCase(getAdditionalAssets.fulfilled, (state, action) => {
				state.getAdditionalAssetsLoading = false;
				state.allAdditionalAssets = action.payload;
			})
			.addCase(getAdditionalAssets.rejected, (state, action) => {
				state.getAdditionalAssetsLoading = false;
			})

			// Save Additional Assets
			.addCase(saveAdditionalAssets.pending, (state) => {
				state.saveAdditionalAssetsLoading = true;
			})
			.addCase(
				saveAdditionalAssets.fulfilled,
				(state, action: PayloadAction<AdditionalAssetsType>) => {
					const testD: AdditionalAssetsType = action.payload;
					state.saveAdditionalAssetsLoading = false;
					state.allAdditionalAssets.unshift(testD);
				}
			)
			.addCase(saveAdditionalAssets.rejected, (state, action) => {
				state.saveAdditionalAssetsLoading = false;
			})

			// Update Additional Assets
			.addCase(updateAdditionalAssets.pending, (state) => {
				state.updateAdditionalAssetsLoading = true;
			})
			.addCase(
				updateAdditionalAssets.fulfilled,
				(state, action: PayloadAction<AdditionalAssetsType>) => {
					state.updateAdditionalAssetsLoading = false;
					message.success("Record updated successfully");
					const index = state.allAdditionalAssets.findIndex(
						(item) => item.id == action.payload.id
					);
					if (index > -1) {
						state.allAdditionalAssets[index] = action.payload;
					}
				}
			)
			.addCase(updateAdditionalAssets.rejected, (state, action) => {
				state.updateAdditionalAssetsLoading = false;
			})

			// Delete Additional Assets
			.addCase(deleteAdditionalAssets.pending, (state) => {
				state.deleteAdditionalAssetsLoading = true;
			})
			.addCase(deleteAdditionalAssets.fulfilled, (state, action) => {
				state.deleteAdditionalAssetsLoading = false;
				message.success("Record deleted successfully");
				state.allAdditionalAssets = state.allAdditionalAssets.filter(
					(item) => item.id !== action.meta.arg
				);
			})
			.addCase(deleteAdditionalAssets.rejected, (state, action) => {
				state.deleteAdditionalAssetsLoading = false;
			})

			// Save additional reminder
			.addCase(saveAdditionalAssetReminder.pending, (state, action) => {
				state.additionalReminderLoading = action.meta.arg.ids[0];
			})
			.addCase(saveAdditionalAssetReminder.fulfilled, (state) => {
				message.success("Email sent to notify the contributor");
				state.additionalReminderLoading = null;
			})
			.addCase(saveAdditionalAssetReminder.rejected, (state) => {
				state.additionalReminderLoading = null;
			})

			// Get Additional Asset Detail
			.addCase(getAdditionalAssetDetail.pending, (state) => {
				state.additionalAssetDetailLoading = true;
				state.additionalAssetDetail = {}
			})
			.addCase(getAdditionalAssetDetail.fulfilled, (state, action) => {
				state.additionalAssetDetailLoading = false;
				state.additionalAssetDetail = action.payload;
			})
			.addCase(getAdditionalAssetDetail.rejected, (state, action) => {
				state.additionalAssetDetailLoading = false;
				state.additionalAssetDetail = {}
			})
		
			// Get All Additional Assets
			.addCase(getAdditionalContributions.pending, (state) => {
				state.additionalContributionsLoading = true;
			})
			.addCase(getAdditionalContributions.fulfilled, (state, action) => {
				state.additionalContributionsLoading = false;
				state.additionalContributions = action.payload;
			})
			.addCase(getAdditionalContributions.rejected, (state, action) => {
				state.additionalContributionsLoading = false;
			})
	},
});

export const { resetAdditionalAssets } = globalSlice.actions;

export default globalSlice.reducer;
