import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContType } from "common/type";
import axios from "./axios";

export const getAssetTags = createAsyncThunk('/tag', async () => {
  try {
    const response = await axios.get('/tag')
    return response.data;
  } catch (error: any) {
    console.log(error?.message,'error', error);
  }
});

export const saveAssetTags = createAsyncThunk('save/tag', async (data: any, thunkAPI) => {
  try {
    const response = await axios.post('/tag',data)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const updateAssetTags = createAsyncThunk('update/tag', async (data: ContType, thunkAPI) => {
  try {
    const response = await axios.patch('/tag/'+data.id,data)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const deleteAssetTags = createAsyncThunk('delete/tag/', async (id: number|null, thunkAPI) => {
  try {
    const response = await axios.delete('/tag/'+id)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getAssetTagsMini = createAsyncThunk('get/tag/mini', async () => {
  try {
    const response = await axios.get('/tag/mini')
    return response.data;
  } catch (error: any) {
    console.log(error?.message,'error', error);
  }
});