import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";
import axiosOr from "axios";
import { PINATA_JWT_TOKEN } from "../common/constants";
import { reArangeFilters } from "utils";

const pinataAPI_URL = "https://api.pinata.cloud/pinning/pinJSONToIPFS";

export const getSuperNFT = createAsyncThunk(
	"get/superNft",
	async (id: any, thunkAPI) => {
		try {
			const response = await axios.get("/superNft/" + id);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const getSuperNFTs = createAsyncThunk("get/superNfts", async () => {
	try {
		const response = await axios.get("/superNft");
		return response.data;
	} catch (error: any) {
		console.log(error?.message, "error", error);
	}
});

export const saveSuperNFT = createAsyncThunk(
	"save/superNft",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.post("/superNft", data);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const mintTheSuperNFT = createAsyncThunk(
	"mint/superNft",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.post("/superNft/mint", data);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const updateSuperNFT = createAsyncThunk(
	"update/superNft/previous",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.patch("/superNft/" + data.id, data);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const updateSuperNFTPreviousButton = createAsyncThunk(
	"update/superNft",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.patch("/superNft/" + data.id, data);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const deleteSuperNFT = createAsyncThunk(
	"delete/superNft",
	async (id: number | null, thunkAPI) => {
		try {
			const response = await axios.delete("/superNft/" + id);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const getSuperNFTDetail = createAsyncThunk(
	"get/superNft/detail",
	async (id: any, thunkAPI) => {
		try {
			const response = await axios.get("/superNft/" + id);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const getRecentNFTCount = createAsyncThunk(
	"get/superNft/recent/{count}",
	async (count: any, thunkAPI) => {
		try {
			const response = await axios.get("superNft/recent/" + count);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const payForNFT = createAsyncThunk(
	"api/payment",
	async (body: any, thunkAPI) => {
		try {
			const response = await axios.post("/payment", body);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const payForKYC = createAsyncThunk(
	"api/payment/kyc",
	async (body: any, thunkAPI) => {
		try {
			const response = await axios.post("/payment/kyc", body);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const payForCredits = createAsyncThunk(
	"api/payment/credits",
	async (body: any, thunkAPI) => {
		try {
			const response = await axios.post("/payment/credits", body);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const pinJSONToIPFS = createAsyncThunk(
	"pinJSONToIPFS",
	async (jsonData: any, thunkAPI) => {
		try {
			const headers = {
				Authorization: `Bearer ${PINATA_JWT_TOKEN}`,
				"Content-Type": "application/json",
			};
			const body = {
				pinataOptions: { cidVersion: 1 },
				pinataMetadata: { name: jsonData.name },
				pinataContent: jsonData,
			};
			const response = await axiosOr.post(pinataAPI_URL, body, { headers });
			return response?.data?.IpfsHash;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return null;
		}
	}
);

export const getRecentDraftedNftCount = createAsyncThunk(
	"/superNft/drafted/recent-count",
	async (filters: any, thunkAPI) => {
		try {
			const queryFilters = reArangeFilters(filters);
			const response = await axios.get(`/superNft/recent-count${queryFilters}`);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const getRecentMintedNftCount = createAsyncThunk(
	"/superNft/minted/recent-count",
	async (filters: any, thunkAPI) => {
		try {
			const queryFilters = reArangeFilters(filters);
			const response = await axios.get(`/superNft/recent-count${queryFilters}`);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const getRecentNftList = createAsyncThunk(
	"/superNft/recent-list",
	async (filters: any, thunkAPI) => {
		try {
			const queryFilters = reArangeFilters(filters);
			const response = await axios.get(`/superNft/recent-list${queryFilters}`);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);

export const getCloneNFTDetail = createAsyncThunk(
	"get/superNft/clone/",
	async (nftId: any, thunkAPI) => {
		try {
			const response = await axios.get("/superNft/clone/" + nftId);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
		}
	}
);
