import { AssetStoreTags } from "store/type";

export const initGlobalState: AssetStoreTags = {
  assetTags: [],
  allAssetTagsMini: [],
  getAssetTagsLoading: false,
  saveAssetTagsLoading: false,
  updateAssetTagsLoading: false,
  deleteAssetTagsLoading: false,
};
