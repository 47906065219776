import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContType } from "common/type";
import axios from "./axios";

export const getContributorsRole = createAsyncThunk('get/contributor/role/all', async () => {
  try {
    const response = await axios.get('/contributor/role/all')
    return response.data;
  } catch (error: any) {
    console.log(error?.message,'error', error);
  }
});


export const saveContributorsRole = createAsyncThunk('save/contributor/role', async (data: any, thunkAPI) => {
  try {
    const response = await axios.post('/contributor/role',data)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const updateContributorsRole = createAsyncThunk('update/contributor/role', async (data: ContType, thunkAPI) => {
  try {
    const response = await axios.patch('/contributor/role/'+data.id,data)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const deleteContributorsRole = createAsyncThunk('delete/contributor/Role', async (id: number|null, thunkAPI) => {
  try {
    const response = await axios.delete('/contributor/role/'+id)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getContributorsRoleMini = createAsyncThunk('/contributor/role/all/mini', async () => {
  try {
    const response = await axios.get('/contributor/role/all/mini')
    return response.data;
  } catch (error: any) {
    console.log(error?.message,'error', error);
  }
});


