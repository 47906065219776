import React from "react";
import { Button, Modal } from 'antd';
import "./modals.scss";
import { CheckCircleFilled, PoweroffOutlined } from '@ant-design/icons';
import { useAppSelector, useAppDispatch } from 'common/hooks/redux';
import { RootState } from "store/index";
import { toggleLogOutModal, toggleLogoutSession } from "store/auth";


/**
 * @param {UploadType} props
 */

const LogOutModal: React.FC = (
): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { logOutModal } = useAppSelector((state: RootState) => state.auth)


  function toggleModal(visible: boolean = false) {
    dispatch(toggleLogOutModal(visible))
  }

  const logoutSession = () => {
    dispatch(toggleLogoutSession(null));
    dispatch(toggleLogOutModal(false));
  };

  return (
    <Modal
      open={logOutModal}
      title=""
      width="auto"
      style={{ maxWidth: '600px', }}
      wrapClassName="log-out"
      closeIcon={false}
      footer={[
        <Button type="primary" onClick={() => logoutSession()}>
          Yes
        </Button>,
        <Button onClick={() => toggleModal(false)}>
          No
        </Button>,
      ]}
    >
      <div className="inner-wrapper">
        <PoweroffOutlined style={{ fontSize: '54px', color: "#ff4d4f", }} />
        <h1>Log out</h1>
        <p>Are you sure you want to log out?</p>
      </div>
    </Modal>
  );
};

export default LogOutModal;