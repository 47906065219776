import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContType } from "common/type";
import axios from "./axios";

export const getContributorsType = createAsyncThunk('get/contributor/type', async () => {
  try {
    const response = await axios.get('/contributor/type/all')
    return response.data;
  } catch (error: any) {
    console.log(error?.message,'error', error);
  }
});


export const saveContributorsType = createAsyncThunk('save/contributor/type', async (data: any, thunkAPI) => {
  try {
    const response = await axios.post('/contributor/type',data)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const updateContributorsType = createAsyncThunk('update/contributor/type', async (data: ContType, thunkAPI) => {
  try {
    const response = await axios.patch('/contributor/type/'+data.id,data)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const deleteContributorsType = createAsyncThunk('delete/contributor/type', async (id: number|null, thunkAPI) => {
  try {
    const response = await axios.delete('/contributor/type/'+id)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getContributorsTypeMini = createAsyncThunk('all/contributor/type/mini', async () => {
  try {
    const response = await axios.get('/contributor/type/mini')
    return response.data;
  } catch (error: any) {
    console.log(error?.message,'error', error);
  }
});


