import React from "react";
import { Avatar, Button, Image } from "antd";
import "./profile_pic.scss";

/**
 * @description Header Component
 * @param {IComponent} props
 * @returns {React.ReactElement}
 */
type PropsType = {
	firstName: string | undefined;
	lastName: string | undefined;
	imageUrl: string | undefined;
	surNameOrder?: boolean;
};

const ProfileAvatar: React.FC<PropsType> = (
	props: PropsType
): React.ReactElement => {
	const { firstName, imageUrl, lastName, surNameOrder } = props;

	return (
		<div className="not_connected">
			{surNameOrder ? (
				<Avatar size={42} src={imageUrl && imageUrl}>
					{" "}
					{lastName?.charAt(0).toUpperCase()}
					{firstName?.charAt(0).toUpperCase()}
				</Avatar>
			) : (
				<Avatar size={42} src={imageUrl && imageUrl}>
					{" "}
					{firstName?.charAt(0).toUpperCase()}
					{lastName?.charAt(0).toUpperCase()}
				</Avatar>
			)}
		</div>
	);
};

export default ProfileAvatar;
