import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	getContributorsType,
	saveContributorsType,
	deleteContributorsType,
	updateContributorsType,
	getContributorsTypeMini,
} from "services/contributorType.service";
import { initGlobalState } from "./default";
import { ContType } from "../../common/type";
import { message } from "antd";
const globalSlice = createSlice({
	name: "global",
	initialState: initGlobalState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// List-contributor-types
			.addCase(getContributorsType.pending, (state) => {
				state.getContributorTypeLoading = true;
			})
			.addCase(getContributorsType.fulfilled, (state, action) => {
				state.getContributorTypeLoading = false;
				state.allContributorTypes = action.payload;
			})
			.addCase(getContributorsType.rejected, (state, action) => {
				state.getContributorTypeLoading = false;
			})

			// Save-contributor-type
			.addCase(saveContributorsType.pending, (state) => {
				// state.getContributorTypeLoading = true;
			})
			.addCase(
				saveContributorsType.fulfilled,
				(state, action: PayloadAction<ContType>) => {
					const testD: ContType = action.payload;
					state.allContributorTypes.unshift(testD);
				}
			)
			.addCase(saveContributorsType.rejected, (state, action) => {
				// state.getContributorTypeLoading = false;
			})

			// Update-contributor-type
			.addCase(updateContributorsType.pending, (state) => {
				state.updateContributorTypeLoading = true;
			})
			.addCase(
				updateContributorsType.fulfilled,
				(state, action: PayloadAction<ContType>) => {
					state.updateContributorTypeLoading = false;
					message.success("Record updated successfully");
					const index = state.allContributorTypes.findIndex(
						(item) => item.id == action.payload.id
					);
					if (index > -1) {
						state.allContributorTypes[index] = action.payload;
					}
				}
			)
			.addCase(updateContributorsType.rejected, (state, action) => {
				state.updateContributorTypeLoading = false;
			})

			// Delete-contributor-type
			.addCase(deleteContributorsType.pending, (state) => {
				state.deleteContributorTypeLoading = true;
			})
			.addCase(deleteContributorsType.fulfilled, (state, action) => {
				state.deleteContributorTypeLoading = false;
				message.success("Record deleted successfully");
				state.allContributorTypes = state.allContributorTypes.filter(
					(item) => item.id !== action.meta.arg
				);
			})
			.addCase(deleteContributorsType.rejected, (state, action) => {
				state.deleteContributorTypeLoading = false;
			})

			// Get All asset types mini
			.addCase(getContributorsTypeMini.fulfilled, (state, action) => {
				state.allContributorTypesMini = action.payload;
			});
	},
});

export const {} = globalSlice.actions;

export default globalSlice.reducer;
