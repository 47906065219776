import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdditionalAssetsType } from "common/type";
import axios from "./axios";

export const getAdditionalAssets = createAsyncThunk('get/additionalAssets', async (superNFTId: any, thunkAPI) => {
  try {
    const response = await axios.get('/additionalAssets?superNFTId='+superNFTId)
    return response.data;
  } catch (error: any) {
    console.log(error?.message,'error', error);
  }
});

export const saveAdditionalAssets = createAsyncThunk('save/additionalAssets', async (data: any, thunkAPI) => {
  try {
    const response = await axios.post('/additionalAssets',data)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const updateAdditionalAssets = createAsyncThunk('update/additionalAssets/{id}', async (data: AdditionalAssetsType, thunkAPI) => {
  try {
    const response = await axios.patch('/additionalAssets/'+data.id,data)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const deleteAdditionalAssets = createAsyncThunk('delete/additionalAssets/{id}', async (id: number|null, thunkAPI) => {
  try {
    const response = await axios.delete('/additionalAssets/'+id)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const saveAdditionalAssetReminder = createAsyncThunk(
	"update/additionalAssets/reminder",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.post(
				"/additionalAssets/reminder",
				data
			);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const getAdditionalAssetDetail = createAsyncThunk('get/additionalAssetDetail/{id}', async (AuditionassetID: any, thunkAPI) => {
  try {
    const response = await axios.get('/additionalAssets/'+AuditionassetID)
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getAdditionalContributions = createAsyncThunk('get/additionalContributions', async (contributorId: any, thunkAPI) => {
  try {
    const response = await axios.get('/additionalAssets?contributorId='+contributorId)
    return response.data;
  } catch (error: any) {
    console.log(error?.message,'error', error);
  }
});
