import axios from 'axios'
import { authHeader } from 'utils';
// import router from '../router'
// import { authHeader } from '../utils/helpers'
// import store from '../store'
// import { COMMON_CONSTANTS } from '../constants/common'
import { API_BASE_URL } from "../common/constants";
import { message } from 'antd';
import { isArray } from 'lodash';

const requestService = axios.create({
    baseURL: API_BASE_URL || '',
    timeout: 100000, //@note timeout changed from 30000 to 100000
})

requestService.interceptors.request.use(
    async (config) => {

        if (config.data?.file) {
            let bodyFormData = new FormData()
            Object.keys(config.data).map(key => {
                bodyFormData.append(key, config.data[key]);
            })
            config.data = bodyFormData
        }
        // config &&
        //     config.loading &&
            // store.dispatch('activateLoader', COMMON_CONSTANTS.ENABLELOADER)
        if (config.url !== 'account/token') {
            config.headers.authorization = await authHeader()
            // config.headers['Access-Control-Allow-Methods'] = '*'
            // config.headers['Access-Control-Allow-Origin'] = '*'
            //config.headers['X-IR-API-KEY'] = APP_CONFIG.VUE_APP_API_KEY

        }
        return config
    },
    (config) => {
        // if (!config.loader) store.dispatch('activateLoader', COMMON_CONSTANTS.DISABLELOADER)
    }
)

requestService.interceptors.response.use(
    (response) => {
        //if there is global loader param
        // response.config.loading &&
        //     store.dispatch('activateLoader', COMMON_CONSTANTS.DISABLELOADER)
        // const { snackbar } = response.config
        // //if there is snackbar obj
        // if (snackbar && snackbar.show) {
        //     store.dispatch('createSnackBar', {
        //         message: response.data.message,
        //         icon: snackbar.icon,
        //         color: snackbar.color
        //     })
        // }
        return response
    },
    (error) => {
        //if there is global loader param
        // error.config.loading && store.dispatch('activateLoader', COMMON_CONSTANTS.DISABLELOADER)
        if (error.code === 'ECONNABORTED') {
            return Promise.reject(error)
        } else if (!error.response) {
            return Promise.reject(error)
        } else if (error.response.status === 401 || error.response.status === 306) {
            // AuthenticationService.removeToken()
            // router.push({ name: 'login', params: { isSessionExpired: 'true' } })
        } else if (error.response && error.response.status !== 404) {
  
        }
        if (
            // AuthenticationService.getToken() == null ||
            error.response.status === 401 ||
            error.response.status === 306
        ) {
            if (error.response.status === 401) {
                // AuthenticationService.removeToken()
                // router.push({ name: '' })
            } else {
                // router.push('/')
            }
        }
        else if (error.response?.data?.message) {
            const errorMsg = error.response?.data?.message;
            if (isArray(errorMsg)) {
                errorMsg.map(msg => message.error(msg))
            } else {
                message.error(errorMsg)
            }
            console.log('error => error',error);
        }
        return Promise.reject(error)
    }
)

export default requestService