import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	getContributorsRole,
	saveContributorsRole,
	deleteContributorsRole,
	updateContributorsRole,
	getContributorsRoleMini,
} from "services/contributorRole.service";
import { initGlobalState } from "./default";
import { ContType } from "../../common/type";
import { message } from "antd";
const globalSlice = createSlice({
	name: "global",
	initialState: initGlobalState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// List-contributor-roles
			.addCase(getContributorsRole.pending, (state) => {
				state.getContributorRoleLoading = true;
			})
			.addCase(getContributorsRole.fulfilled, (state, action) => {
				state.getContributorRoleLoading = false;
				state.allContributorRoles = action.payload;
			})
			.addCase(getContributorsRole.rejected, (state, action) => {
				state.getContributorRoleLoading = false;
			})

			// Save-contributor-role
			.addCase(saveContributorsRole.pending, (state) => {
				// state.getContributorRoleLoading = true;
			})
			.addCase(
				saveContributorsRole.fulfilled,
				(state, action: PayloadAction<ContType>) => {
					const testD: ContType = action.payload;
					state.allContributorRoles.unshift(testD);
				}
			)
			.addCase(saveContributorsRole.rejected, (state, action) => {
				// state.getContributorRoleLoading = false;
			})

			// Update-contributor-role
			.addCase(updateContributorsRole.pending, (state) => {
				state.updateContributorRoleLoading = true;
			})
			.addCase(
				updateContributorsRole.fulfilled,
				(state, action: PayloadAction<ContType>) => {
					state.updateContributorRoleLoading = false;
					message.success("Record updated successfully");
					const index = state.allContributorRoles.findIndex(
						(item) => item.id == action.payload.id
					);
					if (index > -1) {
						state.allContributorRoles[index] = action.payload;
					}
				}
			)
			.addCase(updateContributorsRole.rejected, (state, action) => {
				state.updateContributorRoleLoading = false;
			})

			// Delete-contributor-role
			.addCase(deleteContributorsRole.pending, (state) => {
				state.deleteContributorRoleLoading = true;
			})
			.addCase(deleteContributorsRole.fulfilled, (state, action) => {
				state.deleteContributorRoleLoading = false;
				message.success("Record deleted successfully");
				state.allContributorRoles = state.allContributorRoles.filter(
					(item) => item.id !== action.meta.arg
				);
			})
			.addCase(deleteContributorsRole.rejected, (state, action) => {
				state.deleteContributorRoleLoading = false;
			})

			// Get All asset roles mini
			.addCase(getContributorsRoleMini.fulfilled, (state, action) => {
				state.allContributorRolesMini = action.payload;
			});
	},
});

export const {} = globalSlice.actions;

export default globalSlice.reducer;
