import { ConttributorRevenueStoreType } from "store/type";

export const initGlobalState: ConttributorRevenueStoreType = {
  allContributorRevenue: [],
  revenueDetail: {},
  getContributorRevenueLoading: false,
  saveContributorRevenueLoading: false,
  updateContributorRevenueLoading: false,
  deleteContributorRevenueLoading: false,
  revenueDetailLoading: false,
  commonRevenueLoading: false,
  reminderLoading: null,
  revenueContributions: [],
  revenueContributionsLoading: false,
};
