import { Layout, Image, Button } from 'antd';
import { useNavigate } from "react-router-dom";
import "./sign-in-nav.scss";
import logo from "assets/images/logo.svg";

const { Header } = Layout;

type SignInNavType = {
  name: string;
  path: string;
}

/**
 * @param {SignInNavType} props
 */

const SignInNav: React.FC<SignInNavType> = (
  props: SignInNavType
): React.ReactElement => {
  const { name, path } = props;
  const navigate = useNavigate();

  return (
    <Header className="reg-header">
      <Image
        src={logo}
        preview={false}
      />
      <Button onClick={() => navigate(path)}>
        {name}
      </Button>
    </Header>
  );
};

export default SignInNav;