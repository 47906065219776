import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContType } from "common/type";
import axios from "./axios";

export const getInstituteType = createAsyncThunk('get/institution/type', async () => {
  try {
    const response = await axios.get('/institution/type')
    return response.data;
  } catch (error: any) {
    console.log(error?.message,'error', error);
  }
});

export const saveInstituteType = createAsyncThunk('save/institution/type', async (data: any, thunkAPI) => {
  try {
    const response = await axios.post('/institution/type',data)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const updateInstituteType = createAsyncThunk('update/institution/type', async (data: ContType, thunkAPI) => {
  try {
    const response = await axios.patch('/institution/type/'+data.id,data)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const deleteInstituteType = createAsyncThunk('delete/institution/type', async (id: number|null, thunkAPI) => {
  try {
    const response = await axios.delete('/institution/type/'+id)
    return response.data;
  } catch (error: any) {
    console.log(error?.message, 'error', error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});