import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	getInstituteType,
	saveInstituteType,
	updateInstituteType,
	deleteInstituteType,
} from "services/instituteType.service";
import { initGlobalState } from "./default";
import { ContType } from "../../common/type";
import { message } from "antd";

const globalSlice = createSlice({
	name: "global",
	initialState: initGlobalState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			// Get All institute type
			.addCase(getInstituteType.pending, (state) => {
				state.getInstituteTypeLoading = true;
			})
			.addCase(getInstituteType.fulfilled, (state, action) => {
				state.getInstituteTypeLoading = false;
				state.instituteType = action.payload;
			})
			.addCase(getInstituteType.rejected, (state, action) => {
				state.getInstituteTypeLoading = false;
			})

			// Save Institute type
			.addCase(saveInstituteType.pending, (state) => {
				state.saveInstituteTypeLoading = true;
			})
			.addCase(
				saveInstituteType.fulfilled,
				(state, action: PayloadAction<ContType>) => {
					const testD: ContType = action.payload;
					state.instituteType.unshift(testD);
				}
			)
			.addCase(saveInstituteType.rejected, (state, action) => {
				state.saveInstituteTypeLoading = false;
			})

			// Update Institute type
			.addCase(updateInstituteType.pending, (state) => {
				state.updateInstituteTypeLoading = true;
			})
			.addCase(
				updateInstituteType.fulfilled,
				(state, action: PayloadAction<ContType>) => {
					state.updateInstituteTypeLoading = false;
					message.success("Record updated successfully");
					const index = state.instituteType.findIndex(
						(item) => item.id == action.payload.id
					);
					if (index > -1) {
						state.instituteType[index] = action.payload;
					}
				}
			)
			.addCase(updateInstituteType.rejected, (state, action) => {
				state.updateInstituteTypeLoading = false;
			})

			// Delete Institute type
			.addCase(deleteInstituteType.pending, (state) => {
				state.deleteInstituteTypeLoading = true;
			})
			.addCase(deleteInstituteType.fulfilled, (state, action) => {
				state.deleteInstituteTypeLoading = false;
				message.success("Record deleted successfully");
				state.instituteType = state.instituteType.filter(
					(item) => item.id !== action.meta.arg
				);
			})
			.addCase(deleteInstituteType.rejected, (state, action) => {
				state.deleteInstituteTypeLoading = false;
			});
	},
});

export const {} = globalSlice.actions;

export default globalSlice.reducer;
