import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	getContributors,
	saveContributor,
	updateContributorProfile,
} from "services/contributor.service";
import { initGlobalState } from "./default";
import { ContributorType } from "../../common/type";

const globalSlice = createSlice({
	name: "global",
	initialState: initGlobalState,
	reducers: {
		toggleMiniAddContributorModal: (
			state,
			action: PayloadAction<boolean | number>
		) => {
			state.addMiniContributorModal = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder

			// Contributors list
			.addCase(getContributors.pending, (state) => {
				state.getContributorLoading = true;
			})
			.addCase(getContributors.fulfilled, (state, action) => {
				state.getContributorLoading = false;
				state.contributors = action.payload;
			})
			.addCase(getContributors.rejected, (state, action) => {
				state.getContributorLoading = false;
			})

			// Save Contributor
			.addCase(saveContributor.pending, (state) => {
				state.saveContributorLoading = true;
			})
			.addCase(
				saveContributor.fulfilled,
				(state, action: PayloadAction<ContributorType>) => {
					const testD: ContributorType = action.payload;
					state.saveContributorLoading = false;
					state.contributors.unshift(testD);
				}
			)
			.addCase(saveContributor.rejected, (state, action) => {
				state.saveContributorLoading = false;
			})

	},
});

export const { toggleMiniAddContributorModal } = globalSlice.actions;

export default globalSlice.reducer;
