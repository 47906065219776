import { UploadAssetsStoreType } from "store/type";

export const initGlobalState: UploadAssetsStoreType = {
  MyFiles: [],
  RecentFileCount: [],
  getMyFilesLoading: false,
  saveMyFilesLoading: false,
  getRecentFilesCountLoading: false,
  mainAssetDetail: {},
//   updateAssetTypeLoading: false,
  deleteAssetTypeLoading: false,
  mainAssetDetailLoading: false,
};

