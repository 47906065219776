import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	getCreatedUsers,
	updateCreatedUser,
	getUsersRecentCount,
	getUsersRecentList,
} from "services/createUser.service";
import { initGlobalState } from "./default";
import { UsersTypes } from "../../common/type";
import { message } from "antd";

const globalSlice = createSlice({
	name: "global",
	initialState: initGlobalState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// Sign-in
			.addCase(getCreatedUsers.pending, (state) => {
				state.getUsersLoading = true;
			})
			.addCase(getCreatedUsers.fulfilled, (state, action) => {
				state.getUsersLoading = false;
				state.allUsers = action.payload;
			})
			.addCase(getCreatedUsers.rejected, (state, action) => {
				state.getUsersLoading = false;
			})

			// Update-user
			.addCase(updateCreatedUser.pending, (state) => {
				state.updateUserLoading = true;
			})
			.addCase(
				updateCreatedUser.fulfilled,
				(state, action: PayloadAction<UsersTypes>) => {
					state.updateUserLoading = false;
					message.success("Record updated successfully");
					const index = state.allUsers.findIndex(
						(item) => item.id == action.payload.id
					);
					if (index > -1) {
						state.allUsers[index] = action.payload;
					}
				}
			)
			.addCase(updateCreatedUser.rejected, (state, action) => {
				state.updateUserLoading = false;
			})
		
			// Get User recent count
			.addCase(getUsersRecentCount.pending, (state) => {
				state.usersRecentCountLoading = true;
			})
			.addCase(getUsersRecentCount.fulfilled, (state, action) => {
				state.usersRecentCountLoading = false;
				state.usersRecentCount = action.payload;
			})
			.addCase(getUsersRecentCount.rejected, (state, action) => {
				state.usersRecentCountLoading = false;
			})
		
			// Get User recent list
			.addCase(getUsersRecentList.pending, (state) => {
				state.usersRecentListLoading = true;
			})
			.addCase(getUsersRecentList.fulfilled, (state, action) => {
				state.usersRecentListLoading = false;
				state.usersRecentList = action.payload;
			})
			.addCase(getUsersRecentList.rejected, (state, action) => {
				state.usersRecentListLoading = false;
			})
	},
});

export const {} = globalSlice.actions;

export default globalSlice.reducer;
