import React, { useState } from "react";
import { Button, Modal, Input, Empty, Card, Image } from "antd";
import "./modals.scss";
import { SearchOutlined } from "@ant-design/icons";
import { useAppSelector, useAppDispatch } from "common/hooks/redux";
import { RootState } from "store/index";
import { toggleConnectWalletModal } from "store/global";
import { Connector, useConnect, useDisconnect, useAccount } from "wagmi";

type ConnectType = {
	data: {
		chain: string;
		logo: string;
	}[];
};

/**
 * @param {ConnectType} props
 */

const ConnectWalletModal: React.FC<ConnectType> = (
	props: ConnectType
): React.ReactElement => {
	const { data } = props;
	// const [isConnected, setIsConnected] = useState(false);
	const [searchText, setSearchText] = useState<string>("");
	const dispatch = useAppDispatch();
	const connectWalletModal = useAppSelector(
		(state: RootState) => state.global.connectWalletModal
	);
	const { connectors, connect } = useConnect();
	const { disconnect } = useDisconnect();
	const { isConnected } = useAccount();

	function toggleModal(visible: boolean = false) {
		dispatch(toggleConnectWalletModal(visible));
	}

	function disconnectWallet() {
		disconnect();
		toggleModal(false);
	}

	const onSearch = (e: any) => {
		setSearchText(e.target.value.toLowerCase());
	};

	const filteredConnectors = connectors.filter((connector) =>
		connector.name.toLowerCase().includes(searchText)
	);

	return (
		<Modal
			open={connectWalletModal}
			title="Connect your wallet"
			width="560px"
			wrapClassName="connect-wallet"
			footer={
				isConnected
					? [
						<Button key="back" onClick={() => toggleModal(false)}>
							Cancel
						</Button>,
						<Button type="primary" onClick={disconnectWallet}>
							Disconnect
						</Button>,
					]
					: []
			}
			onCancel={() => toggleModal(false)}
		>
			{
				// isConnected ? (
				//   <div className="connected">
				//     <Image
				//       width="172px"
				//       height="168px"
				//       src='/assets/images/bro.svg'
				//       preview={false}
				//     />
				//     <h1>Get started with your first SuperNFT</h1>
				//     <p>1.Select multiple assets(audio files, images, videos etc) to be part of your SuperNFT.</p>
				//     <p>2.Verify ownership of each asset and add any contributors if needed. </p>
				//     <p>3.Confirm SuperNFT details and mint.</p>
				//     <div className="actions">
				//       <Button key="back">Create superNFT collection</Button>
				//       <Button type="primary">Create superNFT</Button>
				//     </div>
				//   </div>
				// ) :
				<>
					<Input
						placeholder="Search…"
						onChange={onSearch}
						prefix={
							<SearchOutlined
								style={{
									fontSize: "16px",
									color: "#FFFFFF",
									marginRight: "5px",
								}}
							/>
						}
					/>
					<div>
						{filteredConnectors.length === 0 ? (
							<Empty description="No connectors found" />
						) : (
							filteredConnectors.map(
								(connector) =>
									connector.icon && (
										<Card
											key={connector.uid}
											onClick={() => {
												connect({ connector });
												toggleModal(false);
											}}
										>
											<Image
												src={connector.icon}
												preview={false}
												width="40px"
												height="40px"
											/>
											<p>{connector.name}</p>
										</Card>
									)
							)
						)}
					</div>

					{/* <List
              dataSource={connectors}
              grid={{ column: 3, gutter: 8, }}
              renderItem={(item) =>
                <List.Item>
                  <Card>
                    <Image
                      src={`/assets/images/chainlogos/${item.logo}.svg`}
                      preview={false}
                      width="40px"
                      height="40px"
                    />
                    <p>{item.name}</p>
                  </Card>
                </List.Item>
              }
            /> */}
				</>
			}
		</Modal>
	);
};

export default ConnectWalletModal;
