import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { NotificationType } from "store/type";
import { BrowserRouter as Router } from "react-router-dom";
import { requestUser } from "services/auth.service";
import "./App.scss";
import "./core/i18n";
import Route from "./route";
import { setDefaultLanguage, setWindowFunction, updateFavicon, updateTitleNotification } from "./utils";
import { useAppDispatch, useAppSelector } from "common/hooks/redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import NftPreviewModal from "common/components/Modals/NftPreviewModal";
import { WagmiProvider } from "wagmi";
import { config } from "./config";
import { RootState } from "store/index";
import { SERVER_BASE_URL } from "common/constants";
import {
	getUserNotificationsList,
	markReadNotification,
} from "services/createUser.service";
import io from "socket.io-client";
const queryClient = new QueryClient();

const App = (): React.ReactElement => {
	const dispatch = useAppDispatch();
	const { notifications, getNotificationsLoading } = useAppSelector(
		(state: RootState) => state.global
	);
	const { user } = useAppSelector((state: RootState) => state.auth);
	const [localNotifications, setLocalNotifications] =
		useState<NotificationType[]>(notifications);

	const socket = io(SERVER_BASE_URL, {
		query: { userId: user?.id },
	});

	const initialize = (): void => {
		setDefaultLanguage();
	};

	useEffect(() => {
		initialize();
		dispatch(requestUser());
	}, []);

	useEffect(() => {
		if (user?.id) {
			dispatch(getUserNotificationsList({}));
		}
	}, [user, dispatch]);

	useEffect(() => {
		const unreadCount = localNotifications.filter((notif) => !notif.is_read).length;
		updateTitleNotification(unreadCount); // Update title with unread count
	}, [localNotifications]);


	// useEffect(() => {
	// 	const unreadCount = localNotifications.filter(
	// 		(notif) => !notif.is_read
	// 	).length;
	// 	updateFavicon(unreadCount);
	// 	if (unreadCount > 0) {
	// 		// notification.info({
	// 		// 	message: `You have ${unreadCount} new notifications.`,
	// 		// 	// description: localNotifications
	// 		// 	// 	.filter((notif) => !notif.is_read)
	// 		// 	// 	.map((notif) => notif.title)
	// 		// 	// 	.join(", "),
	// 		// });
	// 	}
	// }, [localNotifications]);

	useEffect(() => {
		setLocalNotifications(notifications);
	}, [notifications]);

	return (
		<WagmiProvider config={config}>
			<QueryClientProvider client={queryClient}>
				<div id="complycube-mount"></div>
				<Router>
					<Route />
					<NftPreviewModal />
				</Router>
			</QueryClientProvider>
		</WagmiProvider>
	);
};

export default App;
