import React from "react";
import { Button, Image } from "antd";
import { AssetPreviewTypes } from "common/type";
import { assetPreview } from "utils";
import "./asset_preview.scss";

/**
 * @description Header Component
 * @param {IComponent} props
 * @returns {React.ReactElement}
 */

type PropsType = {
	asset: AssetPreviewTypes;
};

const AssetPreview: React.FC<PropsType> = (
	props: PropsType
): React.ReactElement => {
	const { asset } = props;

	return (
		<div className="asset-preview">
			{(() => {
				const type = asset?.mimetype?.split("/")[0];
				console.log("type", type);
				switch (type) {
					case "image":
						return (
							<Image
								width={"auto"}
								height="auto"
								className="img-height"
								src={assetPreview(asset?.url, asset?.mimetype)}
								preview={false}
							/>
						);
					case "video":
						return (
							<video
								width="100%"
								max-height="500px"
								controls
								src={asset?.url}
								style={{ maxHeight: "500px" }}
							>
								<source src={asset?.url} />
							</video>
						);
					case "application":
						return (
							// <embed
							// 	width="100%"
							// 	height="500px"
							// 	src={asset?.url}
							// 	type="application/pdf"
							// 	onLoad={() => console.log('PDF Loaded')}
							// 	onError={() => console.error('Error loading PDF')}
							// />
							<span style={{ color: "#ffffff" }}>No preview available</span>
						);
					case "audio":
						return (
							<audio controls className="audio audio_player" src={asset?.url}>
								<source src={asset?.url} />
							</audio>
						);
					case "text":
						return (
							<Image
								width={"100%"}
								height="300px"
								src={assetPreview(asset?.url, asset?.mimetype)}
								preview={false}
							/>
						);
					default:
						return <h1>{type}</h1>;
				}
			})()}
		</div>
	);
};

export default AssetPreview;
