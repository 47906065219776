import React from "react";
import {
	UsergroupAddOutlined,
	SettingOutlined,
	BellOutlined,
	LogoutOutlined,
	ArrowLeftOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu, Image, Avatar, List, Button, Tooltip, Badge, Switch } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { assetPreview } from "utils";
import "./profile_side_bar.scss";
import { useAppSelector, useAppDispatch } from "common/hooks/redux";
import { toggleLogoutSession } from "store/auth";
import { toggleLogOutModal } from "store/auth";
import { RootState } from "store";
import { ROLES } from "common/constants/index";
import LogOutModal from "common/components/Modals/LogOutModal";
import logo from "assets/images/logo.svg";
import kwik from "assets/images/KwikTrust.svg";
import ProfileAvatar from "common/components/ProfileAvatar";

/**
 * SideMenu
 * @description 네비게이션 바
 * @param {} props
 * @returns {React.ReactElement}
 */

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
	icon: React.ReactNode,
	label: React.ReactNode,
	key: React.Key,
	disabled?: boolean,
	children?: MenuItem[],
	type?: "group"
): MenuItem {
	return {
		key,
		icon,
		children,
		label,
		type,
		disabled,
	} as MenuItem;
}

const ProfileSideBar: React.FC = (): React.ReactElement => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state: RootState) => state.auth);
	const { notifications, getNotificationsLoading } = useAppSelector(
		(state: RootState) => state.global
	);
	const isAdmin = user?.roles?.includes(ROLES.ADMIN);
	const { pathname } = useLocation();

	const onClick: MenuProps["onClick"] = (e) => {
		navigate(e.key);
	};

	const hasUnreadNotifications = notifications.some(notification => notification.is_read === false);

	const items: MenuProps["items"] = [
		getItem(
			<SettingOutlined />,
			"Profile settings",
			`${user?.contributor == null ? "/user-profile" : "/contributor-profile"}`
		),
		// getItem(<ArrowLeftOutlined />, "Dashboard", "/"),
		getItem(hasUnreadNotifications ?
			<Badge dot>
				<BellOutlined />
			</Badge> : <BellOutlined />,
			<Tooltip title="Notification">Notification</Tooltip>,
			"/profile-notifications"
		),
		getItem(
			<UsergroupAddOutlined />,
			"Identity verification",
			"/profile-identity-verification"
		),
	];

	const logout = () => {
		dispatch(toggleLogOutModal(true));
	};

	const handleBackNavigation = () => {
		navigate(-1);
	};

	const data = [
		{
			link: "/#",
			name: "Logout",
			icon: <LogoutOutlined />,
		},
		{
			link: `${user?.contributor == null ? "/user-profile" : "/contributor-profile"
				}`,
			name: "User Profile",
			icon: <SettingOutlined />,
		},
		{
			link: "/profile-notifications",
			name: "Notifications",
			icon: (hasUnreadNotifications ?
				<Badge dot>
					<BellOutlined />
				</Badge> : <BellOutlined />
			),
		},
	];

	return (
		<div className="side-nav">
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Image
					src={logo}
					preview={false}
					width="56%"
					onClick={() => navigate("/")}
					style={{ cursor: "pointer" }}
				/>
			</div>
			<div className="profile-pic-cont">
				<ArrowLeftOutlined onClick={handleBackNavigation} />
				{user?.contributor == null ? (
					<Avatar
						onClick={() => navigate("/user-profile")}
						size={48}
						src={
							user?.profile_pic ? (
								assetPreview(
									user?.profile_pic?.url,
									user?.profile_pic?.mimetype
								)
							) : (
								<ProfileAvatar
									firstName={user?.first_name}
									lastName={user?.last_name}
									imageUrl={user?.profile_pic?.url}
								/>
							)
						}
						style={{ border: "1px solid #CAD8E5", cursor: "pointer" }}
					/>
				) : (
					<Avatar
						onClick={() => navigate("/contributor-profile")}
						size={48}
						src={
							user?.profile_pic ? (
								assetPreview(
									user?.profile_pic?.url,
									user?.profile_pic?.mimetype
								)
							) : (
								<ProfileAvatar
									firstName={user?.contributor?.first_name}
									lastName={user?.contributor?.last_name}
									imageUrl={user?.profile_pic?.url}
								/>
							)
						}
						style={{ border: "1px solid #CAD8E5", cursor: "pointer" }}
					/>
				)}
				<div className="info-inner">
					{user?.contributor == null ? (
						<div>
							<h2>{`${user?.first_name} ${user?.last_name}`}</h2>
							<span>
								KTX Credits: <b>{user?.credits ? Number(user.credits).toFixed(2) : 0}</b>
							</span>
						</div>
					) : (
						<div>
							<h2>{`${user?.contributor?.first_name} ${user?.contributor?.last_name}`}</h2>
							<span>
								KTX Credits: <b>{user?.credits ? Number(user.credits).toFixed(2) : 0}</b>
							</span>
						</div>
					)}
				</div>
			</div>
			<Menu
				onClick={onClick}
				style={{ width: 250, paddingBottom: "140px" }}
				defaultSelectedKeys={["1"]}
				defaultOpenKeys={["sub1"]}
				selectedKeys={[pathname]}
				mode="inline"
				items={items}
			/>
			<div className="info-wrapper">
				<List
					dataSource={data}
					grid={{ column: 3, gutter: 8 }}
					renderItem={(item) => (
						<List.Item>
							<Tooltip title={item.name}>
								<Button
									onClick={
										item.name == "Logout"
											? () => logout()
											: () => navigate(item.link)
									}
									icon={item.icon}
								/>
							</Tooltip>
						</List.Item>
					)}
				/>
				<div className="poweredby">
					<p>Powered by</p>
					<Image src={kwik} preview={false} width="110px" />
				</div>
				<LogOutModal />
			</div>
		</div>
	);
};

export default ProfileSideBar;
