import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";
import { reArangeFilters } from "../utils/index";
export const getContributors = createAsyncThunk("contributor", async () => {
	try {
		const response = await axios.get("/contributor/search");
		return response.data;
	} catch (error: any) {
		console.log(error?.message, "error", error);
	}
});

export const getContributorsBySearch = async (filters: any) => {
	try {
		const queryFilters = reArangeFilters(filters);
		const response = await axios.get("/contributor/search"+queryFilters);
		return response.data;
	} catch (error: any) {
		console.log(error?.message, "error", error);
	}
};

export const saveContributor = createAsyncThunk(
	"save/contributor",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.post("/contributor", data);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const updateContributorProfile = createAsyncThunk(
	"update/contributor/profile",
	async (data: any, thunkAPI) => {
		try {
			const response = await axios.patch("/contributor/" + data.id, data);
			return response.data;
		} catch (error: any) {
			console.log(error?.message, "error", error);
			return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);
