
import React from "react";
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import './menu.scss'
import { useAccount } from 'wagmi'
import { getCenterShort } from "utils"
import { useAppDispatch } from 'common/hooks/redux'
import { toggleConnectWalletModal } from "store/global"
import { useNavigate } from "react-router-dom";


/**
 * @description Header Icons Component
 * @param {IconMenuType} props
 * @returns {React.ReactElement}
 */
const IconMenu: React.FC = (
): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { address, isConnected } = useAccount()
  return (
    <div className="header-left">
      <h1 onClick={() => navigate("/nftlibrary", { replace: true })} style={{ color: '#FFF', fontFamily: 'Poppins', fontSize: '24px', fontWeight: 700, cursor: 'pointer' }}>SuperNFT Library</h1>
      {isConnected && <Button className="status-btn" htmlType="submit" onClick={() => dispatch(toggleConnectWalletModal(true))}>
        <span className="connected">Connected</span>
        <span className="meta">{getCenterShort(address)}</span>
        <EditOutlined style={{ fontSize: '16px' }} />
      </Button>}
    </div>
  );
};

export default React.memo(IconMenu);
